import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

const HostInformation = ({openEvent, setOpenEvent}) =>{
    const [t, i18n] = useTranslation("global");
    // Use state to track the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Function to handle window resize and update the state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const closeClick = () => {
      if (openEvent) {
        const element = document.getElementById(openEvent.name);
      if (element) {
          const elementRect = element.getBoundingClientRect();
          var offset = 0
          if(windowWidth < 769){
            offset = 80; // Adjust this value to set the desired offset in pixels
          }else{
            offset = 150
          }

          // Scroll to a position above the element by subtracting the offset
          window.scrollBy({
            top: elementRect.top - offset,
            behavior: 'smooth',
          });
      }
      }
      setOpenEvent([[{ room: "" }]])
    };

    useEffect(() => {
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      if (openEvent && openEvent.host_name && windowWidth < 769) {
        const element = document.getElementById(openEvent.host_name);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [openEvent, windowWidth]);

  return (
        <div style={{marginTop:'1%', width:'100%', paddingLeft:'10%', paddingRight:'10%'}} id={openEvent.host_name}>
        {openEvent &&
            (
                <div className="col-start-1 lg:grid lg:grid-cols-5 pt-10 lg:pr-3 lg:w-100 min-h-500">
                  <div className="justify-self-center lg:col-start-1 mx-auto">
                    <img
                        className="h-fit col-start-1 object-cover justify-self-center rounded-full mx-auto block"
                        src={`data:image/png;base64,${openEvent.picture}`}
                        alt="logo"
                        width="250px" 
                        height="250px"
                    />
                    <div className="col-start-1 col-span-2 text-center py-10 text-3xl font-bold" style={{fontFamily: "Bebas Neue"}}>
                      {openEvent.host_name}
                    </div>
                    {windowWidth > 768 && (
                      <button
                        className="bg-green-ticket text-center rounded-full col-span-2 w-40 h-20 mb-10 text-black mx-auto block"
                        onClick={() => closeClick()}
                      >
                       {t("home.close")}
                      </button>
                    )}
                  </div>
                  <div className="lg:ml-3 lg:col-start-2 col-span-2 px-4 text-justify mx-auto">
                    <div className="font-bold text-3xl" style={{wordBreak:'break-word', fontFamily: "Bebas Neue"}}>
                      {openEvent.host_name}
                    </div>
                    <div> {openEvent.host_description}</div>
                  </div>
                  <div className="lg:ml-3 lg:col-start-4 col-span-2 px-4 text-justify mx-auto mt-10 lg:mt-0">
                    <div className="font-bold text-3xl" style={{wordBreak:'break-word', fontFamily: "Bebas Neue"}}>
                      {openEvent.name}
                    </div>
                    <div> {openEvent.event_description}</div>
                  </div>

                </div>
            )}
            {windowWidth < 769 && (
              <button
                className="bg-green-ticket text-center rounded-full col-span-2 mt-5 w-40 h-20 mb-10 text-black mx-auto block"
                onClick={() => closeClick()}
              >
                {t("home.close")}
              </button>
            )}

          </div>
    )
}

export default HostInformation;