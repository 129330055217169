export const LANGUAGES = [
  {
    key: "en",
    value: "en",
    image: {
      avatar: true,
      src: "https://img.favpng.com/3/5/21/flag-of-the-united-kingdom-flag-of-great-britain-flag-of-england-png-favpng-q7ZWJzpaYN34ZfrVGRepJsUKN_t.jpg",
    },
  },
  {
    key: "de",
    //   text: "de",
    value: "de",
    image: {
      avatar: true,
      src: "https://img.favpng.com/19/1/19/flag-of-germany-german-language-flag-of-france-png-favpng-MpxaHGB8QjKfMPjAYd3fEyvYm.jpg",
    },
  },
];
