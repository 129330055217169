import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ti from "../../svg/ti.png";
import x3 from "../../svg/x3.png";
import qr from "../../svg/tik-qr.png";
import bgTickets from "../../svg/bg-tickets.png";
import logo1 from "../../svg/logo1.png";
import ticketIcon from "../../svg/ticket-icon.png";
import ticketPin from "../../svg/tickets-pin.png";

const TicketForm = (props) => {
  const [inputValues, setInputValues] = useState({});
  const [open, setOpen] = useState(false);
  const [obj, setObj] = useState([]);
  const cancelButtonRef = useRef(null);
  const [t, i18n] = useTranslation("global");
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const form = useRef();
  const [loading, setLoading] = useState(true);
  const [showGreencheck, setShowGreencheck] = useState([props.objShowOK]);
  const [showOK, setShowOK] = useState(
    "bg-slate-400 text-white xl:px-52 px-6 xl:w-fit w-full sm:w-auto h-32 text-4xl md:text-5xl cursor-not-allowed"
  );
  const [discounts, setDiscounts] = useState(
    Array(props.numberTickets).fill(0)
  );
  const [totalPrice, setTotalPrice] = useState(0);

  const [previousNumberOfTickets, setPreviousNumberOfTickets] = useState(
    props.numberTickets
  );

  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const [discountData, setDiscountData] = useState({});

  useEffect(() => {
    const handleNumberOfTicketsIncrease = () => {
      setShowOK(
        "bg-slate-400 text-white xl:px-52 px-6 xl:w-fit w-full sm:w-auto h-32 text-4xl md:text-5xl"
      );
      /**let testObj = props.objShowOK;

      console.log("Obj show ok -  "+ props.objShowOK);
      let resultName = testObj.every((i) => {
        return i.nameOk === true;
      });
      let resultEmail = testObj.every((i) => {
        return i.emailOk === true;
      });
      if (resultName === true && resultEmail === true) {
        setShowOK(
          "bg-blue-tikets text-white xl:px-52 px-6 xl:w-fit w-full sm:w-auto h-32 text-4xl md:text-5xl"
        );
      } else {
        setShowOK(
          "bg-slate-400 text-white xl:px-52 px-6 xl:w-fit w-full sm:w-auto h-32 text-4xl md:text-5xl"
        );
      }
       **/
      forceUpdate();
    };

    // Check if the number of tickets has increased
    if (props.numberTickets > previousNumberOfTickets) {
      // Call the function when the number of tickets increases
      handleNumberOfTicketsIncrease();
    } else if (props.numberTickets < previousNumberOfTickets) {
      obj.forEach((item, index) => {
        const fakeEvent = {
          target: {
            id: index,
            name: item.name,
            email: item.email,
            discount: item.discount,
            value: item.name,
            deleteId: 0
          },
        };

        //This is to fake a handleChangeName since it already does everything we want.
        handleChangeName(fakeEvent);
      });
    }

    // Update the previousNumberOfTickets state to the current value of props.NumberofTickets
    setPreviousNumberOfTickets(props.numberTickets);

    const handleResize = () => {
      setIsScreenSmall(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.numberTickets, previousNumberOfTickets]);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  let handleClose = (event) => {
    setOpen(false);
    refresh_total(event);
  };
  let handleChangeName = (event) => {
    let testObj = props.objShowOK;
    let lastItem = event.target.id;

    if (event.target.value.length > 0) {
      testObj[lastItem].nameOk = true;
      setShowGreencheck(testObj);
    } else {
      testObj[lastItem].nameOk = false;
      setShowGreencheck(testObj);
    }
    refresh_total(event);
    let resultName = true;
    let resultEmail = true;
    testObj.forEach((item, index) => {
      if (index !== lastItem && index < props.numberTickets) {
        if (!item.nameOk) {
          resultName = false;
        }
        if (!item.emailOk) {
          resultEmail = false;
        }
      }
    });

    if (resultName === true && resultEmail === true) {
      setShowOK(
        "bg-blue-tikets text-white xl:px-52 px-6 xl:w-fit w-full sm:w-auto h-32 text-4xl md:text-5xl"
      );
    } else {
      setShowOK(
        "bg-slate-400 text-white xl:px-52 px-6 xl:w-fit w-full sm:w-auto h-32 text-4xl md:text-5xl"
      );
    }
    forceUpdate();
  };

  const handleChangeEmail = (event) => {
    let testObj = props.objShowOK;
    let emailTest = isValidEmail(event.target.value);
    let lastItem = event.target.id;

    testObj[lastItem].emailOk = emailTest;
    setShowGreencheck(testObj);
    refresh_total(event);

    let resultName = true;
    let resultEmail = true;

    testObj.forEach((item, index) => {
      if (index !== lastItem && index < props.numberTickets) {
        if (!item.nameOk) {
          resultName = false;
        }
        if (!item.emailOk) {
          resultEmail = false;
        }
      }
    });

    if (resultName === true && resultEmail === true) {
      setShowOK(
        "bg-blue-tikets text-white xl:px-52 px-6 xl:w-fit w-full sm:w-auto h-32 text-4xl md:text-5xl"
      );
    } else {
      setShowOK(
        "bg-slate-400 text-white xl:px-52 px-6 xl:w-fit w-full sm:w-auto h-32 text-4xl md:text-5xl"
      );
    }
    forceUpdate();
  };

  const handleChangeDiscount = async (i) => {
    let discount_code = inputValues[i];
    let ticketIndex = i; // Get the index of the ticket
    let updatedDiscounts = [...discounts]; // Create a copy of the state array
    let lastItem = i
    let testObj = props.objShowOK;
    let flag = false;

    
    if (!discountData.hasOwnProperty(discount_code)) {
      try {
        const response = await fetch(
          "https://backend.pnpcongress.eu/api/discount/" + discount_code,
          {method:"GET"}
        );
        const data = await response.json();

        // Assuming the API response contains a property called 'discount'
        const discount = data["data"]["percentage"];

        if (data["message"] === "Success") {
          let tempDiscData = discountData;
          tempDiscData[discount_code] = data;
          setDiscountData(tempDiscData);
          if (
            data["data"]["ticket_id"] === null ||
            data["data"]["ticket_id"] === props.ticketId
          ) {
            if (data["data"]["unlimited"] === true) {
              testObj[lastItem].discountOk = true;
            } else {
              obj.forEach(function (item, index) {
                if (item.discount_id === discount_code) {
                  flag = true;
                }
              });
              if (flag) {
                testObj[lastItem].discountOk = false;
              } else {
                testObj[lastItem].discountOk = true;
              }
            }
          } else {
            flag = true;
          }
        } else {
          testObj[lastItem].discountOk = false;
        }
      } catch (error) {
        testObj[lastItem].discountOk = false;
        // Handle API call errors if needed
        console.error("Error fetching discount:", error);
      }
    } else {
      if (discountData[discount_code]["message"] === "Success") {
        if (
          discountData[discount_code]["data"]["ticket_id"] === null ||
          discountData[discount_code]["data"]["ticket_id"] ===
            props.ticketId
        ) {
          if (discountData[discount_code]["data"]["unlimited"] === true) {
            testObj[lastItem].discountOk = true;
          } else {
            obj.forEach(function (item, index) {
              if (item.discount_id === discount_code) {
                flag = true;
              }
            });
            if (flag) {
              testObj[lastItem].discountOk = false;
            } else {
              testObj[lastItem].discountOk = true;
            }
          }
        } else {
          flag = true;
        }
      } else {
        testObj[lastItem].discountOk = false;
      }
    }

    if (!flag) {
      setShowGreencheck(testObj);
      updatedDiscounts[ticketIndex] = discount_code;
      setDiscounts(updatedDiscounts);
      setTotalPrice(totalPriceWithDiscount);
      refresh_total(discount_code); 
      forceUpdate();
    }
  };

  let background = {
    backgroundImage: `url(${bgTickets})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    // padding: "4px",
    fontFamily: "Bebas Neue",
  };

  const totalPriceWithDiscount = obj.reduce((total, ticket, index) => {
    const discount = discounts[index];

    // Calculate the price with the discount applied
    const discountedPrice = props.item.price * (1 - ticket.discount);

    // If a discount code is entered, use the discounted price, otherwise use the original price
    return total + (discount ? discountedPrice : props.item.price);
  }, 0);

  const buyTicket = (e) => {
    e.preventDefault();
    setOpen(false);

    obj.vat = e.target[0].value;
    obj.forEach(function (item, index) {
      item.vat = e.target[0].value;
    });

    // Create a new object with "tickets" and "vat" properties
    const formData = {
      tickets: JSON.stringify(obj),
      vat: e.target[0].value,
    };

    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://backend.pnpcongress.eu/checkout";

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = formData[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  const openSummary = async (e) => {
    e.preventDefault();

    const updatedObj = []; // Create a new array to store the updated objects

    for (let i = 0; i < props.numberTickets ; i ++) {
      // Assuming the API endpoint accepts the email as a parameter to fetch the discount value
      const email = form.current.elements[`email_${i}`].value;
      const discount_code = form.current.elements[`discount_${i}`].value;
      const checked = form.current.elements[`discount_${i}_check`];
      if (!discountData.hasOwnProperty(discount_code)) {
        // Make the API call to fetch the discount value
        try {
          const response = await fetch(
            "https://backend.pnpcongress.eu/api/discount/" +
            discount_code
          );
          const data = await response.json();

          // Assuming the API response contains a property called 'discount'
          let discount = data["data"]["percentage"];

          let discout_id = discount_code;

          if (data["data"]["unlimited"] !== true) {
            updatedObj.forEach(function (item, index) {
              if (item.discount_id === discout_id) {
                discout_id = "";
                discount = 0;
              }
            });
          }

          // Push the updated object with the fetched discount value
          updatedObj.push({
            name: form.current.elements[`name_${i}`].value,
            email: email,
            discount: checked !== undefined  ? discount : 0,
            discount_id: checked !== undefined  ? form.current.elements[`discount_${i}`].value : "",
            id: props.ticketId,
            deleteId: i
          });
        } catch (error) {
          updatedObj.push({
            name: form.current.elements[`name_${i}`].value,
            email: email,
            discount: 0,
            discount_id: "",
            id: props.ticketId,
            deleteId: i
          });
          // Handle API call errors if needed
          console.error("Error fetching discount:", error);
        }
      } else {
        if (discountData[discount_code]["message"] === "Success") {
          // Assuming the API response contains a property called 'discount'
          let discount = discountData[discount_code]["data"]["percentage"];

          let discout_id = form.current.elements[`discount_${i}`].value;

          if (discountData[discount_code]["data"]["unlimited"] !== true) {
            updatedObj.forEach(function (item, index) {
              if (item.discount_id === discout_id) {
                discout_id = "";
                discount = 0;
              }
            });
          }

          // Push the updated object with the fetched discount value
          updatedObj.push({
            name: form.current.elements[`name_${i}`].value,
            email: email,
            discount: checked !== undefined  ? discount : 0,
            discount_id: checked !== undefined ? form.current.elements[`discount_${i}`].value : "",
            id: props.ticketId,
            deleteId: i
          });
        }
      }
    }
    setObj(updatedObj); // Set the updated object array as the new state
    setLoading(false); // Set loading to false once the API calls are completed
    setOpen(true);
    setOpen(true);
  };

  const refresh_total = async (e, discountcode = null) => {
    //e.preventDefault();
    const updatedObj = []; // Create a new array to store the updated objects
    let updatedDiscounts = [...discounts];
    let discount = 0;
    let discount_code = "";

    for (let i = 0; i < props.numberTickets ; i ++) {
      // Assuming the API endpoint accepts the email as a parameter to fetch the discount value
      const email = form.current.elements[`email_${i}`].value;
      let temp_flag = false;
      if (
        form.current.elements[`discount_${i}`].value !== "" &&
        form.current.elements[`discount_${i}`].value !== " "
      ) {
        discount_code = form.current.elements[`discount_${i}`].value;
        if (!discountData.hasOwnProperty(discount_code)) {
          
          try {
            const response = await fetch(
              "https://backend.pnpcongress.eu/api/discount/" +
                discount_code,
                {method:"GET"}
            );
            const data = await response.json();

            // Assuming the API response contains a property called 'discount'
            discount = data["data"]["percentage"];

            // Push the updated object with the fetched discount value
            updatedObj.push({
              name: form.current.elements[`name_${i}`].value,
              email: email,
              discount: discount,
              discount_id: discount_code,
              id: props.ticketId,
              deleteId: i
            });

            temp_flag = true;
          } catch (error) {
            
            updatedObj.push({
              name: form.current.elements[`name_${i}`].value,
              email: email,
              discount: discount,
              discount_id: discount_code,
              id: props.ticketId,
              deleteId: i
            });
            temp_flag = true;
            // Handle API call errors if needed
            console.error("Error fetching discount:", error);
          }
        } else {
          if (discountData[discount_code]["message"] === "Success") {
            discount = discountData[discount_code]["data"]["percentage"];
            // Push the updated object with the fetched discount value
            updatedObj.push({
              name: form.current.elements[`name_${i}`].value,
              email: email,
              discount: discount,
              discount_id: discount_code,
              id: props.ticketId,
              deleteId: i
            });
            temp_flag = true;
          }
        }
      } else {
        if (!temp_flag) {
          updatedObj.push({
            name: form.current.elements[`name_${i}`].value,
            email: email,
            discount: discount,
            discount_id: discount_code,
            id: props.ticketId,
            deleteId: i
          });
        }
      }
    }

    setObj(updatedObj); // Set the updated object array as the new state
    setLoading(false); // Set loading to false once the API calls are completed
  };

  // const clickDeleteHandler = (i) => {
  //   console.log("number" + i);

  //   let temp_obj = obj;

  //   temp_obj.splice(i, 1);
  //   let temp_objShowOk = props.objShowOK;
  //   temp_objShowOk.splice(i, 1);
  //   props.setObjShowOk(temp_objShowOk);

  //   setObj(temp_obj);
  //   forceUpdate();
  // };

  const clickDeleteHandler = (i) => {
    let objArray = obj;
    objArray = obj.filter(function (obj1) {
      return obj1.deleteId !== i;
    });
    setObj(objArray);
  };

  return (
    <>
      <form className=" bg-white  xl:pr-20" ref={form} onSubmit={openSummary}>
        <div className="border-b-2 w-48 border-dotted xl:ml-0 ml-6"></div>
        {[...Array(props.numberTickets)].map((e, i) => (
          <div key={i} className="xl:pl-10 xl:pr-0 px-6">
            <div className="grid grid-cols-6 pt-10 pr-48 pl-10 bg-white content-center"></div>
            <div className="border-2 grid grid-cols-12 py-10 flex items-center bg-white">
              <div className="xl:-ml-16 -ml-5 xl:text-4xl text-xl bg-white col-start-1 col-span-1 border-2 text-center rounded-full xl:h-32 h-10 xl:w-32 w-10 flex justify-center items-center text-blue-tikets">
                {i + 1}.
              </div>
              <div className="col-start-2 xl:col-span-8 col-span-10 -mt-10">
                <div className="grid grid-cols-12 border-b-2 xl:w-11/12 xl:ml-10 ">
                  <div className="relative -ml-10 z-0 xl:w-11/12 mb-3 group col-start-1 col-span-11">
                    <input
                      autoComplete="off"
                      type="text"
                      name={`name_${i}`}
                      id={i}
                      className="block pl-10 py-4 px-0 w-full mt-8 -mb-8 text-2xl text-blue-tikets bg-transparent appearance-none dark:text-blue-tikets dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer placeholder:italic"
                      placeholder={t("home.name-player")}
                      required
                      onChange={handleChangeName}
                    />
                  </div>
                  {showGreencheck[i]?.nameOk && (
                    <img
                      className="self-end sm:place-self-center h-10"
                      src={ti}
                      alt="logo"
                    />
                  )}
                </div>
                <div className="grid grid-cols-12 border-b-2 xl:w-11/12 xl:ml-10 ">
                  <div className="relative -ml-10 z-0 xl:w-11/12 sm:w-2/3 mb-3 group col-start-1 col-span-11">
                    <input
                      autoComplete="off"
                      type="email"
                      name={`email_${i}`}
                      id={i}
                      className="block bold pl-10 py-4 px-0 w-full my-8 -mb-8 text-2xl text-blue-tikets bg-transparent appearance-none dark:text-blue-tikets dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer placeholder:italic"
                      placeholder={t("home.e-mail-player")}
                      required
                      onChange={handleChangeEmail}
                    />
                  </div>
                  {showGreencheck[i]?.emailOk && (
                    <img
                      className="self-end sm:place-self-center h-10"
                      src={ti}
                      alt="logo"
                    />
                  )}
                </div>
              </div>
              <div
                className="text-center xl:col-span-3 col-span-12 xl:ml-10 -mt-4"
                style={{ fontFamily: "sf-pro-regular" }}
              >
                <div className="2xl:text-3xl xl:text-2xl col-span-3 py-4 text-gray-tikets text-center">
                  {t("tickets.discount_code")}
                </div>
                <div className="flex justify-center">
                  <div className="block col-span-3 border-2 mx-2 py-4 px-2 text-center xl:w-9/12 w-10/12 text-2xl text-blue-tikets bg-transparent appearance-none dark:text-blue-tikets dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer flex justify-center">
                    <input
                      style={{ fontFamily: "sf-pro-regular" }}
                      autoComplete="off"
                      type="text"
                      name={`discount_${i}`}
                      id={`discount_${i}`}
                      className="block text-center xl:w-9/12 w-10/12 text-2xl text-blue-tikets bg-transparent focus:outline-none focus:ring-0 flex justify-center"
                      placeholder=" "
                      onChange={()=>{
                        const updatedInputValues = { ...inputValues }
                        updatedInputValues[i] = document.getElementById(`discount_${i}`).value
                        setInputValues(updatedInputValues);
                      }}
                    />
                    <label
                      style={{ fontFamily: "sf-pro-regular" }}
                      className="peer-focus:font-medium absolute text-2xl text-blue-tikets italic pl-10 dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-500 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5"
                    >
                      {t("home.name-player")}
                    </label>
                    {showGreencheck[i]?.discountOk && (
                      <>
                        <img
                          className="place-self-center h-10"
                          src={ti}
                          alt="logo"
                        />
                        <input
                          hidden
                          name={`discount_${i}_check`}
                          value={"true"}
                       />
                    </>
                    )}
                  </div>

                </div>
                <button
                  type="button"
                  style={{ fontFamily: "sf-pro-medium" }}
                  className="col-start-6 bg-blue-tikets text-white p-5 w-10/12 text-2xl mt-10 py-12"
                  onClick={() => handleChangeDiscount(i)}
                  >
                    {t("tickets.checkdiscount")}
                  </button>
              </div>
            </div>
          </div>
        ))}
        <div className="border-b-2 w-48 pt-10 border-dotted xl:ml-0 ml-6 "></div>
        <div className="flex justify-between items-center flex-col sm:flex-row xl:pl-10 xl:items-end">
          <div className="bold text-5xl py-10 sm:ml-5">
            {t("tickets.total")}{" "}
            <span className="text-blue-tikets">
              {!loading ? totalPriceWithDiscount.toFixed(2) + "€" : 0 + "€"}
            </span>
          </div>
          <div className="justify-content-center p-5">
            <button
              className={props.numberTickets === 0 ? "bg-slate-400 text-white xl:px-52 px-6 xl:w-fit w-full sm:w-auto h-32 text-4xl md:text-5xl cursor-not-allowed" : showOK}
              type="submit"
              disabled = {props.numberTickets === 0}
              style={{ fontFamily: "sf-pro-regular" }}
            >
              {t("tickets.proceed_to_payment")}
            </button>
          </div>
        </div>
      </form>
      <Transition.Root
        show={open}
        as={Fragment}
        style={{ fontFamily: "sf-pro-medium" }}
      >
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto justify-self-center">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="xl:w-4/5 flex justify-center ">
                  <Dialog.Panel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-full sm:mx-20 mt-20 sm:mt-72 xl:px-16">
                    <div
                      className="flex content-start justify-end flex-wrap sm:pt-0 mt-0"
                      onClick={handleClose}
                      type="button"
                    >
                      <button>
                        <img
                          className="place-self-center h-6 lg:mr-0 mr-4 mt-4 xl:mt-16"
                          src={x3}
                          alt="logo"
                        />
                      </button>
                    </div>
                    <div className="bg-white xl:px-4 px-10 pb-4 pt-5 sm:pt-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="grid-cols-1 mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <div className="grid grid-cols-12">
                            <Dialog.Title
                              as="h3"
                              className="text-3xl xl:col-start-2 col-start-1 xl:col-span-8 col-span-12 text-blue-tikets font-semibold leading-6 flex xl:justify-start justify-center items-center"
                              style={{ fontFamily: "sf-pro-medium" }}
                            >
                              {t("tickets.purchase_checkout")}
                            </Dialog.Title>
                          </div>
                          {loading ? (
                            <div>{t("tickets.loading")}</div>
                          ) : (
                            obj.map((e, i) => (
                              <>
                                <div
                                  key={i}
                                  className="mt-2 xl:py-5 md:py-5 grid grid-cols-12 text-2xl xl:justify-items-starts justify-items-center content-center flex items-center h-fit sm:mb-0 mt-0 sm:py-0"
                                >
                                  {/* {!isScreenSmall ? (
                                    <> */}
                                  {/* <div className="xl:col-start-1 mb-2 xl:mb-0 col-start-5 col-span-1 border-2 text-center rounded-full h-28 w-28 flex items-center justify-center text-4xl text-blue-tikets"> */}
                                  <div className="xl:col-start-1 mb-2 xl:mb-0 col-start-1 xl:col-span-1 col-span-12 border-2 text-center rounded-full h-24 w-24 xl:h-28 xl:w-28 lg:h-28 lg:w-28 xl:h-32 xl:w-32 flex items-center justify-center sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl text-4xl text-blue-tikets">
                                    {/* <div className="xl:col-start-1 mb-2 xl:mb-0 col-start-1 col-span-1 xl:border-2 xl:text-center xl:rounded-full xl:h-28 xl:w-28 sm:col-start-1 sm:ml-0 sm:mr-auto sm:h-20 sm:w-20  lg:h-28 lg:w-28 xl:h-32 xl:w-32 flex items-center justify-center sm:text-2xl md:text-3xl lg:text-4xl xl:text-4xl text-blue-tikets"> */}
                                    {i + 1}.
                                  </div>
                                  <div className="xl:col-start-2 col-start-1 xl:col-span-8 col-span-12 grid xl:grid-cols-10 grid-cols-11">
                                    <img
                                      className="col-start-1 col-span-5 xl:col-span-4 place-self-center w-full sm:h-fit h-fit"
                                      src={qr}
                                      alt="logo"
                                    />
                                    <div
                                      className="col-start-6 xl:col-start-5 xl:col-span-5 col-span-6 text-white text-center text-s w-full h-full place-items-stretch -ml-1 sm:pl-0 pl-2"
                                      // className="col-start-5 sm:col-span-5 col-span-6 text-white text-center text-s w-full h-full place-items-stretch -ml-1 sm:pl-0 pl-6"
                                      style={background}
                                    >
                                      <div
                                        className="col-start-9 col-span-4 sm:pl-2 pl-1 text-left grid place-items-stretch h-full"
                                        style={{
                                          fontFamily: "sf-pro-regular",
                                        }}
                                      >
                                        <img
                                          className="sm:w-40 w-16 h-max xl:pt-6 pt-1 relative xl:-top-6 sm:-ml-0 -ml-2"
                                          src={logo1}
                                          alt="logo"
                                        />
                                        <div className="pl-4 sm:text-4xl text-[10px] xl:-mt-6 -mt-8 h-2 sm:-ml-0 -ml-4">
                                          {props.item.event_name}
                                        </div>
                                        <div className="sm:h-full h-2 xl:-mt-0 -mt-4 leading-3 sm:-ml-0 -ml-4">
                                          <div className="pl-4 sm:text-2xl text-[8px]">
                                            {e.name}
                                          </div>
                                          <div className="pl-4 sm:text-2xl text-[8px] place-self-start">
                                            {e.email}
                                          </div>
                                        </div>

                                        <div>
                                          <div className="grid grid-cols-2 sm:pl-4 flex items-stretch place-self-start sm:h-full h-2">
                                            <div className="grid grid-cols-8 flex items-center leading-3">
                                              <img
                                                className="w-10 col-span-1 xl:-mt-0 -mt-4"
                                                src={ticketIcon}
                                                alt="logo"
                                              />
                                              <div className="grid grid-cols-1 col-span-6 sm:pl-4 pl-1 leading-3">
                                                <div className="sm:text-xs text-[4px]">
                                                  {props.item.name}
                                                </div>
                                                <div className="sm:text-xs text-[4px]">
                                                {t("tickets.person")},{" "}
                                                  {props.item.from ===
                                                  props.item.to ? (
                                                    props.item.from
                                                  ) : (
                                                    <span>
                                                      {props.item.from}{" "}
                                                      <br></br>
                                                      {props.item.to}{" "}
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="grid grid-cols-8 flex items-center xl:-ml-0 -ml-3">
                                              <img
                                                className="lg:w-10 w-10 col-span-1 xl:-mt-0 -mt-4 "
                                                src={ticketPin}
                                                alt="logo"
                                              />
                                              <div className="sm:text-sm text-[8px] grid grid-cols-1 col-span-6 sm:pl-4  uppercase leading-3 xl:-ml-0 ml-1">
                                                {props.item.location}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* </>
                                  ) : (
                                    ""
                                  )} */}
                                  <div className="lg:col-start-10 col-start-1 xl:col-span-2 lg:col-span-4 sm:col-span-4 col-span-7 grid place-items-stretch h-full py-5 sm:col-start-1 col-start-1 col-span-12 sm:py-0 sm:-ml-2 sm:mr-2 mt-5 ">
                                    <div className="flex flex-col justify-start items-start h-full">
                                      <div className="text-blue-tikets pb-4 2xl:text-4xl ">
                                        {props.item.name}
                                      </div>
                                      <div className="sm:pb-4 text-sm sm:text-base ">
                                        {e.name}
                                      </div>
                                      <div className="pb-6 sm:pb-4 text-sm sm:text-base">
                                        {e.email}
                                      </div>
                                      <div className="lg:text-4xl">
                                        {t("tickets.price")}{" "}
                                        <span className="text-blue-tikets">
                                          {" "}
                                          {(
                                            props.item.price.toFixed(2) -
                                            props.item.price.toFixed(2) *
                                              e.discount
                                          ).toFixed(2)}
                                          €
                                        </span>
                                      </div>
                                    </div>
                                    <button
                                      className="flex items-end text-blue-400 text-2xl mb-10"
                                      hidden={obj.length === 1}
                                      onClick={() =>
                                        clickDeleteHandler(i)
                                      }
                                    >
                                      {t("tickets.delete")}
                                    </button>
                                  </div>
                                </div>
                                <div className="border-b-2 w-48 border-dotted"></div>
                              </>
                            ))
                          )}

                          <div className="grid place-items-stretch grid-cols-12 pt-10 text-4xl">
                            <div className="lg:col-start-2 col-start-1 col-span-5 text-2xl text-left">
                              {obj.length} x {props.item.name}
                            </div>
                            {/* {isScreenSmall ? (
                              <div className="col-start-1 col-span-5 text-sm pt-2">
                                {props.item.location}
                              </div>
                            ) : (
                              ""
                            )} */}
                            <div className="col-start-10 sm:-ml-0 -ml-16 col-span-4">
                              <div className="lg:text-left text-right">
                                {t("tickets.total")}{" "}
                                <span className="text-blue-tikets">
                                  {totalPriceWithDiscount.toFixed(2)}€
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b-2 mx-10 w-48 pt-10 border-dotted"></div>
                    <form className="py-10 sm: py-2" onSubmit={buyTicket}>
                      <div className="relative ml-10 z-0 mr-10 mb-3 group">
                        <button
                          style={{ fontFamily: "sf-pro-medium" }}
                          className="col-start-6 bg-blue-tikets text-white p-5 w-full text-4xl mt-10 py-12"
                          
                        >
                          {t("tickets.proceed_to_payment")}
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default TicketForm;
