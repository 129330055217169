import React from "react";
import logo from "../svg/logo2.png";
import x2 from "../svg/x2.png";
import x3 from "../svg/x3.png";
import { Link } from "react-router-dom";

// import { useTranslation } from "react-i18next";

const PaymentNotSuccessful = () => {
  // const [t, i18n] = useTranslation("global");

  return (
    <div className="grid grid-cols-1  bg-black h-screen w-screen z-50 fixed top-0 left-0 right-0">
      <div className="grid grid-cols-1 p-6 bg-white w-9/12 flex place-self-center">
        <div className="grid grid-cols-2">
          <img className="h-16" src={logo} alt="logo" />
          <Link
            className="flex content-start justify-end flex-wrap"
            to="/tickets"
          >
            <img className="place-self-center h-2" src={x3} alt="logo" />
          </Link>
        </div>
        <img className="place-self-center h-10" src={x2} alt="logo" />
        <div className="place-self-center text-lg text-red-600">
          Your payment was not successful!
        </div>
        <div className="place-self-center pb-16 text-lg text-center">
          There was a problem with your payment. Please try again or contact the
          necessary support.
        </div>
      </div>
    </div>
  );
};

export default PaymentNotSuccessful;
