import React, { useEffect, useState } from 'react';

const EventEmptyDot = ({index, setOpenEvent, setSelectedRoom}) => {

    const referencePoints = [
        { maxWidth: 1023, translateX: -17, translateY: 0 },
        { maxWidth: 2561, translateX: 0, translateY: -21 },
      ];
      
      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
      const [translateX, setX] = useState(0);
      const [translateY, setY] = useState(0);
  
      // Function to handle window resize and update the state
      const handleResize = () => {
        const newWindowWidth = window.innerWidth;
        setWindowWidth(newWindowWidth);
      };
  
      
    useEffect(() => {
        const matchingReference = referencePoints.find(
            (point) => windowWidth <= point.maxWidth
          );
          if (matchingReference) {
            // Set translateX and translateY based on the matching reference
            setX(matchingReference.translateX);
            setY(matchingReference.translateY);
          }
    },[windowWidth])

    const divTranslateYStyle = {
        transform: `translateY(${translateY}px)`,
        transform: `translateY(${translateY}px)`,
        fontFamily: "BebasNeueRegular"
      };
    
      const divTranslateXStyle = {
        transform: `translateX(${translateX}px)`,
        fontFamily: "BebasNeueRegular"
      };  
    
      
    useEffect(() => {
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    return(    
        <>
        {windowWidth < 1024 && (
            <div className='min-h-[200px] break-words' style={divTranslateXStyle}>
            <button
                key={index+ 1}
                className="text-left"
                style={{ fontFamily: "BebasNeueRegular"}}
                disabled
            >

            {(
            
            <div className='grid grid-flow-col auto-cols-auto'>
                <div>
                    <div className="lg:ml-0 ml-4 lg:-mt-0 -mt-10 lg:h-full h-40">
                            <div
                                className={
                                    "w-full col-start-" +
                                    index +
                                    " col-span-1 pt-6"
                                }
                            >
                    {(
                        <>
                        <div className="w-8 h-8 bg-white rounded-full lg:-mt-5 lg:-ml-1 -ml-8 ">
                            {" "}
                        </div>
                        <div className="w-6 h-6 bg-black rounded-full lg:-mt-7 lg:-ml-0 -ml-7 -mt-7">
                            {" "}
                        </div>
                        </>
                    )}
                            </div>
                        </div>
                </div>
            </div>
            )}
            
        </button>
        </div>
  )}
    {windowWidth >= 1024 && (
            <div className='min-h-[200px] break-words' style={divTranslateYStyle}>
            <button
                key={index+ 1}
                className="text-left"
                style={{ fontFamily: "BebasNeueRegular"}}
                disabled
            >

            {(
            
            <div className='grid md:auto-rows-auto md:auto-cols-auto lg:auto-rows-auto auto-cols-auto'>
                <div>
                    <div className="lg:ml-0 ml-4 lg:-mt-0 -mt-10 lg:h-full h-40">
                            <div
                                className={
                                    "w-full col-start-" +
                                    index +
                                    " col-span-1 pt-6"
                                }
                            >
                    {(
                        <>
                        <div className="w-8 h-8 bg-white rounded-full lg:-mt-5 lg:-ml-1 -ml-8 ">
                            {" "}
                        </div>
                        <div className="w-6 h-6 bg-black rounded-full lg:-mt-7 lg:-ml-0 -ml-7 -mt-7">
                            {" "}
                        </div>
                        </>
                    )}
                            </div>
                        </div>
                </div>
            </div>
            )}
            
        </button>
        </div>
  )}

        </>
    ) 
}

export default EventEmptyDot;