import EventDiamond from "./EventDiamond";
import EventDot from "./EventDot";
import EventEmptyDot from "./EventEmptyDot";
import HostInformation from "./HostInformation";
import React, { useEffect, useState } from 'react';

function parseTimeString(timeString) {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(":");

  // Create a new Date object with a default date (e.g., 1970-01-01)
  // and set the hours and minutes from the time string
  const date = new Date(1970, 0, 1, parseInt(hours), parseInt(minutes));

  return date;
}

const EventsBar = ({
  setOpenEvent,
  events,
  openEvent,
  setSelectedRoom,
  eventRoom,
  eventTimes,
  date,
}) => {
  // Combine all event times into a single array
  const allEventTimes = eventTimes[date] ? eventTimes[date] : [];
  allEventTimes.sort();
  const dotWidth = `${(100 / allEventTimes.length)}%`;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {

    if (openEvent.room && !(openEvent.host_name) && windowWidth < 769) {
      const element = document.getElementById("Schedule");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [openEvent, windowWidth]);

  return (
    <>
      <div
        className="sm:col-span-12 col-span-12 sm:w-8/12 lg:text-4xl text-2xl pb-8 w-1/2"
        style={{ fontFamily: "Bebas Neue", marginLeft:"13%"}}
      >
        {eventRoom}
      </div>

      <div
        className={`lg:border-t-8 lg:border-l-0 border-l-4 border-white col-start-1 lg:col-span-12 col-span-12 lg:grid lg:grid-cols-${allEventTimes.length} pl-4 lg:pl-0 font-normal ml-2 mx-auto`}
        style={{ width: "73%", margin: "0 auto" , gridAutoFlow: "column", gridTemplateColumns: `repeat(${allEventTimes.length}, ${dotWidth})`}}
        id={eventRoom}
      >
        {/* Iterate through all event times */}
        {allEventTimes.map((eventTime, index) => {
          // Find events within a 15-minute gap
          const matchingEvents = events.filter((event) => {
            // Convert the event time strings to Date objects for comparison
            const eventStartTime = parseTimeString(event.timeStart);
            const currentEventTime = parseTimeString(eventTime);

            // Calculate the time difference in minutes
            const timeDifferenceMinutes = Math.abs(
              (eventStartTime - currentEventTime) / (1000 * 60)
            );
            // Check if the time difference is less than or equal to 15 minutes
            return timeDifferenceMinutes <= 10;
          });

          if (matchingEvents.length > 0) {
            return matchingEvents.map((matchingEvent, index) => {
              if (matchingEvent.event_type === "work") {
                return (
                  <EventDot
                    key={index}
                    event={matchingEvent}
                    index={index}
                    setOpenEvent={setOpenEvent}
                    setSelectedRoom={setSelectedRoom}
                    openEvent={openEvent}
                  />
                );
              } else if (matchingEvent.event_type === "break") {
                return (
                  <EventDiamond
                    key={index}
                    event={matchingEvent}
                    index={index}
                    setSelectedRoom={setSelectedRoom}
                  />
                );
              }

              return null;
            });
          } else {
            // No matching event, render an empty dot
            return (
              <EventEmptyDot
                key={index}
                setOpenEvent={setOpenEvent}
                setSelectedRoom={setSelectedRoom}
              />
            );
          }
        })}
      </div>
      {openEvent !== "" &&
        openEvent.room === eventRoom &&
        events.length > 0 && (
          <HostInformation key={openEvent} openEvent={openEvent} setOpenEvent={setOpenEvent} />
        )}
    </>
  );
};

export default EventsBar;
