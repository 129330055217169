import React, {useEffect, useState} from "react";
// import { useTranslation } from "react-i18next";
import freitag from "../../svg/freitag.png";
import samstag from "../../svg/samstag.png";
import sonntag from "../../svg/sonntag.png";
import logo1 from "../../svg/logo1.png";
import photo from "../../svg/testPhoto.jpeg";
import EventDot from "./EventDot";
import EventDiamond from "./EventDiamond";
import HostInformation from "./HostInformation";
import EventsBar from "./EventsBar";

const Schedule = (props) => {
  // const [t, i18n] = useTranslation("global");
  const [schedulePicture, setschedulePicture] = useState(0);
  const [openEvent, setOpenEvent] = useState([[{room: ""}]]);
  const [schedule, setSchedule] = useState({});
  const [eventsTimes, setEventsTimes] = useState({});
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedDate, setSelectedate] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [selectedBackground, setSelectedBackground] = useState(freitag);
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const dateHandler = (date) => {
    setSelectedate(date)
    setOpenEvent("");
    getRoomWithMostEventsByDay();
  };

  let background_picture;
  let list_rooms = undefined;

  useEffect(()=>{
    setSelectedLanguage(props.language);
    const fetchSchedule = async () => {
      try {
        const response = await fetch(
          "https://backend.pnpcongress.eu/api/all_events/"+props.language
        );
        const json_data = await response.json();

        if (json_data["message"] === "Success") {
          await format_data(json_data);
          await getRoomWithMostEventsByDay();
        } else {
          //
        }
      } catch (error) {
        // Handle API call errors if needed
        console.error("Error fetching events:", error);
      }
    }
    fetchSchedule();
  }, [props.language]);


  useEffect(()=>{
    let flag = false;
    Object.keys(schedule).forEach((date, index) => {
      if (selectedDate === date && index === 0){
        setSelectedBackground(freitag);
        flag = true;
      }else if(selectedDate === date && index === 1){
        setSelectedBackground(samstag);
        flag = true;
      }else if(!flag){
        setSelectedBackground(sonntag);
      }
    });
  },[selectedDate]);

  useEffect(() => {
    if (!hasEffectRun && Object.keys(schedule).length > 0) { 
      if(Object.keys(eventsTimes).length > 0){
        setFirstDay();
        setHasEffectRun(true);
      }
    }
  }, [eventsTimes]);

  useEffect(() => {
    getRoomWithMostEventsByDay();
    if(openEvent){
      Object.keys(schedule).forEach((day) => {
        const rooms = schedule[day];
        Object.keys(rooms).forEach((room) => {
          const events = schedule[day][room];
          events.forEach((event) => {
            if(event.date === openEvent.date && event.timeStart === openEvent.timeStart && event.timeEnd === openEvent.timeEnd && event.host_name === openEvent.host_name){
              setOpenEvent(event);
            } 
          })
        })
      })
    }
    
  },[schedule]);

  
  async function setFirstDay(){
    setSelectedate(Object.keys(schedule)[0]);
  }

  async function format_data(data){

    let days = {};
    data.data.map((item, index)=>{
      if (days[item.date]){
        days[item.date].push(item);
      }else{
        days[item.date] = [item];
      }
    });

    const dates = Object.keys(days);

// Step 2: Sort the keys (dates) in ascending order
    dates.sort((a, b) => a.localeCompare(b));

// Step 3: Create a new object with sorted keys and corresponding values
    const sortedDays = {};
    dates.forEach(date => {
      sortedDays[date] = days[date];
    });

    const processedData = {};

    data.data.forEach(event => {
      const date = event.date;
      const room = event.room;

      if (!processedData[date]) {
        processedData[date] = {};
      }

      if (!processedData[date][room]) {
        processedData[date][room] = [];
      }

      processedData[date][room].push(event);
    });

    // Sort events by starting hour within each date and room
    for (const date in processedData) {
      for (const room in processedData[date]) {
        processedData[date][room].sort((a, b) => {
          const timeA = a.timeStart;
          const timeB = b.timeStart;
          return timeA.localeCompare(timeB);
        });
      }
    }

    const datesArray = Object.entries(processedData);
    // Sort the dates in ascending order
    datesArray.sort(([dateA], [dateB]) => dateA.localeCompare(dateB));
    const sortedData = Object.fromEntries(datesArray);

    for (const date in processedData) {
      sortedData[date] = {};
  
      // Get the list of rooms for the current date and sort them alphabetically
      const rooms = Object.keys(processedData[date]).sort();
  
      // Populate the sortedData with sorted rooms
      rooms.forEach((room) => {
        sortedData[date][room] = processedData[date][room];
      });
    }
    setSchedule(sortedData);
  }

  async function getRoomWithMostEventsByDay() {
    const maxRoomsPerDay = {}; // Object to store the room with the most events for each day
    const maxEventTimesPerDay = {}; // Object to store the timeStarts of events for the room with the most events per day
  
    // Iterate through the schedule object
    Object.keys(schedule).forEach(date => {
      const rooms = schedule[date];
      let maxRoom = null; // Variable to store the room with the most events for the current day
      let maxEventCount = 0; // Variable to store the maximum event count for the current day
      const allEventTimes = []; // Array to store all timeStarts of events for the current day
  
      // Iterate through the rooms on each date
      Object.keys(rooms).forEach(room => {
        const events = rooms[room];
        const eventCount = events.length;
  
        // Check if the current room has more events than the current maximum
        if (eventCount > maxEventCount) {
          maxEventCount = eventCount;
          maxRoom = room;
        }
  
        // Add all event times to the allEventTimes array
        events.forEach(event => {
          const eventTime = event.timeStart;
  
          if (!allEventTimes.includes(eventTime)) {
            allEventTimes.push(eventTime);
          }
        });
      });
  
      // Store the maximum room for the current day
      maxRoomsPerDay[date] = maxRoom;
  
      // Store all event times for the current day in maxEventTimesPerDay
      maxEventTimesPerDay[date] = allEventTimes;
    });

    setEventsTimes(maxEventTimesPerDay);
  }

  return (
      <div style={{maxWidth:'100%', width:'100%'}} id="Schedule">
      {props.language && selectedBackground && (
          <div style={{maxWidth:'100%', width:'100%'}}>
            {schedule.length !== 0 && (
                <div className="bg-black h-full bg-scroll scroll-smooth overflow-auto" style={{maxWidth:'100%',width:'100%'}}>
                  <div
                      className="bg-scroll scroll-smooth object-scale-down lg:bg-cover bg-contain"
                      style={{
                        backgroundImage: `url(${selectedBackground})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100%",
                        backgroundPositionY: "top",
                        width: '100%',
                      }}
                  >
                    <div
                        className="mx-auto grid grid-cols-5 lg:w-9/12 w text-white text-2xl font-bold underline-offset-0 flex justify-center bg-scroll scroll-smooth object-scale-down pt-10 ">
                      <img
                          className="lg:w-60 col-start-1 sm:col-span-4 col-span-5 lg:h-full h-24 lg:justify-self-start justify-self-center"
                          src={logo1}
                          alt="logo"
                      />
                      
                      <div
                          className="col-start-1 sm:col-span-3 col-span-5 grid grid-cols-3 sm:w-8/12 lg:text-4xl text-2xl pb-2 place-items-start lg:pl-0 pl-4">
                        {Object.keys(schedule).map((item, index) => { // Cada item uma dia
                          const formattedDate = item.replace(/-/g, '.');
                          return (
                              <div key={index + 1}style={{marginRight:"10px"}}>
                                <button
                                    onClick={() => dateHandler(item)}
                                    type="button"
                                    id={item}
                                    className={`col-span-1 text-gray-400 from-green-white to-green-dark hover:text-gray-200 focus:ring-4 focus:outline-none focus:text-white font-medium py-4 text-center mr-5 ring-transparent lg:text-start sm:pl-6 lg:justify-self-start justify-self-center lg:text-3xl ${item === selectedDate ? 'text-white' : ''}`}
                                    style={{fontFamily: "Bebas Neue"}}
                                >
                                  {formattedDate}
                                  {item === selectedDate && (
                                      <p className="hover:text-gray-200 focus:ring-4 focus:outline-none focus:text-white font-medium lg:text-3xl"
                                      >
                                        {schedule[item][Object.keys(schedule[item])[0]]['0']['day_of_week']}
                                      </p>
                                  )}
                                </button>
                              </div>
                          );
                        })}
                        
                      </div>
                      
                    </div>
                    <div className="grid lg:grid-cols-1 grid-cols-">
                      {selectedDate && (Object.keys(schedule[selectedDate]).map((item, index)=>{
                        return(
                            <div key={item}
                                 className="lg:w-9/12 text-white text-2xl underline-offset-0 flex justify-center bg-scroll scroll-smooth object-scale-down pt-10 lg:pl-6 pl-4" style={{width:'100%'}}>
                              <div style={{width:'100%', maxWidth:'100%'}}>
                                <EventsBar
                                    events={schedule[selectedDate][item]}
                                    setOpenEvent={setOpenEvent}
                                    openEvent={openEvent}
                                    setSelectedRoom={setSelectedRoom}
                                    eventRoom={item}
                                    eventTimes={eventsTimes}
                                    date={selectedDate}>
                                </EventsBar>
                              </div>
                            </div>
                        )
                      }))}
                    </div>
                  </div>
                </div>
            )}
          </div>
      )}
      </div>
  );
};

export default Schedule;
