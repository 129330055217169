import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import TicketForm from "./TicketForm";

const TicketsForms = (props) => {
  const [t, i18n] = useTranslation("global");
  const [numberTickets, setNumberTickets] = useState(1);
  const [previousNumberOfTickets, setPreviousNumberOfTickets] = useState(1);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [objShowOK, setObjShowOK] = useState([
    {
      id: 0,
      nameOk: false,
      emailOk: false,
      discountOk: false,
    },
  ]);

  let flag = false;

  const updateObjShowOkState = (newValue) => {
    flag = true;
    setObjShowOK(newValue);
    setNumberTickets(newValue.length);
  };

  const updateNumberOfTickets = (newValue) => {
    setNumberTickets(newValue);
  };

  useEffect(() => {
    let testObj = [];
    if (objShowOK.length > 0) {
      testObj = objShowOK;
    }

    let diff = 0;

    diff = numberTickets - previousNumberOfTickets;

    let value = 0;
    if (diff < 0) {
      value = -diff;
    } else {
      value = diff;
    }
    for (let i = 0; i < value; i++) {
      const newId = i + +numberTickets;
      const existingIndex = testObj.findIndex((item) => item.id === newId);

      if (diff > 0) {
        testObj.push({
          id: newId,
          nameOk: false,
          emailOk: false,
          discountOk: false,
        });
      } else {
        if (diff !== 0) {
          testObj.pop();
        }
      }
    }

    setObjShowOK(testObj);
    setPreviousNumberOfTickets(testObj.length);
  }, [numberTickets, objShowOK]);

  const numberHandler = (number) => {
    setNumberTickets(number);
    forceUpdate();
  };

  return (
    <>
      <div className="grid grid-cols-1 xl:pr-20 xl:px-20 bg-white text-3xl xl:-mt-2 -mt-8 xl:py-10 md:py-10 sm:py-10">
        <div className="w-full">
          <div
            style={{ fontFamily: "sf-pro-regular" }}
            className="text-gray-tikets xl:pl-6 xl:pr-20 pl-6 pr-6 text-base sm:text-sm md:text-md xl:text-xl"
          ></div>

          <div className="grid grid-cols-10 py-10 xl:pl-0 pl-6">
            <div className="w-2 flex items-center">{t("tickets.amount")}</div>
            <label
              style={{ fontFamily: "Poppins" }}
              className="peer-focus:font-medium absolute text-3xl text-blue-tikets italic pl-10 dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-500 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5"
            >
              1
            </label>
            <input
              autoComplete="off"
              type="number"
              name="number"
              id="number"
              className="block border-2 xl:ml-12 ml-24 text-center xl:w-full w-28 h-14 text-3xl text-blue-tikets bg-transparent dark:text-blue-tikets dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer text-4xl "
              placeholder=" "
              value={numberTickets}
              required
              onInput={(e) => numberHandler(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <TicketForm
            numberTickets={Number(numberTickets)}
            ticketId={props.id}
            price={Number(props.price)}
            item={props.item}
            objShowOK={objShowOK}
            setObjShowOk={updateObjShowOkState}
            setNumberTickets={updateNumberOfTickets}
          />
        </div>
      </div>
    </>
  );
};

export default TicketsForms;
