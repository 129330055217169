import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import AnchorLink from "react-anchor-link-smooth-scroll";
import dices from "../svg/dices.svg";
import ticket from "../svg/ticketGreen.png";
import h1 from "../svg/3x.png";
import Schedule from "./components/Schedule";

const HomePageTeachers = () => {
  const [t, i18n] = useTranslation("global");
  const [count, setCount] = useState(0);
  const form = useRef();
  const form2 = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);
    emailjs
      .sendForm(
        "service_n3eetpg",
        "template_amw7vd3",
        form.current,
        "kvvx_padstf0o02_b"
      )
      // .sendForm("gmail", "template_752w77a", form.current, "355wjdlIIykPtD9o8")
      .then(
        (result) => {
          console.log(result.text);
          alert("Thank you for contacting us");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const sendProgramEmail = (e) => {
    e.preventDefault();
    console.log(form2.current);
    emailjs
      .sendForm(
        "service_n3eetpg",
        "template_i8n2y0c",
        form2.current,
        "kvvx_padstf0o02_b"
      )
      // .sendForm("gmail", "template_h4c464n", form2.current, "355wjdlIIykPtD9o8")
      .then(
        (result) => {
          console.log(result.text);
          alert("Thank you for contacting us");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  let background = {
    backgroundImage: `url(${h1})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "top",
  };

  return (
    <div className="App  mx-auto">
      <div className="bg-local lg:h-screen justify-items-center pt-40 flex justify-center">
        <div className="w-full " style={background}>
          <div
            style={{
              fontFamily: "Bebas Neue",
              textShadow: "rgba(0,0,0,0.5) 1px 0 10px",
            }}
            className="2xl:pt-60 sm:pt-40  text-white font-bold text-center text-5xl"
          >
            20.10.2023 - 22.10.2023
          </div>

          <div
            className="text-white  text-center w-screen text-s uppercase"
            style={{
              fontFamily: "Bebas Neue",
              textShadow: "rgba(0,0,0,0.5) 1px 0 10px",
            }}
          >
            Franckeplatz, 1 Haus 37, 06110 Halle
          </div>
          <div
            style={{
              fontFamily: "Bebas Neue",
              textShadow: "rgba(0,0,0,0.5) 1px 0 10px",
            }}
            className="pt-8 px-10 text-white w-full text-center 2xl:text-9xl xl:text-9xl lg:text-6xl md:text-3xl sm:text-3xl"
          >
            {t("home.game-studios")}
          </div>
          <div className="pt-8 w-full grid grid-cols-1 gap-4 place-content-center pt-2">
            <div
              style={{ fontFamily: "Poppins" }}
              className="border-white py-6 px-20 text-white text-center text-lg  place-self-center lg:-mt-0 -mt-4"
            >
              <AnchorLink
                className="border-solid bg-teal-600/50 hover:bg-green-white hover:text-white border-2 bg border-white py-6 px-20 text-white text-center text-lg inset-x-2/4 place-self-center "
                href="#moreInfo"
                id="about"
              >
                {t("home.see-more")}
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
      <div id="moreInfo" style={{height:"100px"}}/>
      <div className="grid pt-20 grid-cols-1 gap-4 justify-items-center">
        <section className="grid grid-cols-1 gap-4">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 items-start">
            <img
              src={dices}
              className="lg:justify-self-end justify-self-center w-80"
              alt="logo"
            />
            <div className="border-dotted border-l-4 ">
              <div
                style={{ fontFamily: "Bebas Neue" }}
                className="text-green-white lg:text-left text-center text-5xl pl-2"
              >
                {t("home.generl-info")}
              </div>
              <div
                style={{ fontFamily: "Poppins" }}
                className="text-black lg:text-justify text-center text-base place-items-center pt-8 px-2 lg:w-2/3"
              >
                {t("home.general-info-text")}
                <br /> <br />
                {t("home.location")}
                <br />
                <a
                  id="abstracts"
                  className="text-cyan-400"
                  href="https://www.francke-halle.de/en/"
                >
                  https://www.francke-halle.de/en
                </a>
                <br />
                Franckeplatz 1 <br />
                Haus 37
                <br />
                06110 Halle
              </div>
            </div>
          </div>
        </section>
        <section className="grid grid-cols-1 gap-4 justify-items-center pt-12">
          <div
            style={{ fontFamily: "Bebas Neue" }}
            className="text-green-white text-center text-5xl uppercase"
          >
            {t("home.submit-program-item")}
          </div>
          <div
            style={{ fontFamily: "Poppins" }}
            className="text-black text-center text-base  lg:w-1/2 place-items-center px-2"
          >
            {t("home.submit-program-item-text.text1")}
            <br /> <br />
            {t("home.submit-program-item-text.text2")}
            <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text.text3")}
            </p>
            <br />
            {t("home.submit-program-item-text.text4")}
            <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text.text5")}
            </p>
            <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text.text6")}
            </p>
            <br />
            {t("home.submit-program-item-text.text7")}
            <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text.text8")}
            </p>
            <br />
            {t("home.submit-program-item-text.text9")}
            <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text.text10")}
            </p>
            <br />
            {t("home.submit-program-item-text.text11")}
            <br /> <br />
          </div>
          <form
            ref={form2}
            onSubmit={sendProgramEmail}
            className="relative lg:w-2/3 w-full pt-12"
            style={{
              width:"90%"
            }}
          >
            <div className="relative z-0 w-full mb-3 group bg-white border-2 border-green-white" >
              <input
                autoComplete="off"
                type="text"
                name="name"
                id="name"
                className="block pl-10 py-4 px-0 w-full text-base text-black bg-transparent appearance-none dark:text-green-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label className="peer-focus:font-medium absolute text-base text-green-white italic pl-10 dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5">
                {t("home.name")}
              </label>
            </div>
            <div className="relative z-0 w-full mb-3 group border-2 border-green-white bg-white">
              <input
                autoComplete="off"
                type="email"
                name="email"
                id="email"
                className="block pl-10 py-4 px-0 w-full text-base text-black bg-transparent appearance-none dark:text-green-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label className="peer-focus:font-medium absolute text-base text-green-white italic pl-10  dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5">
                {t("home.e-mail")}
              </label>
            </div>
            <div className="relative z-0 w-full mb-3 group border-2 border-green-white bg-white">
              <input
                autoComplete="off"
                type="text"
                name="subject"
                id="subject"
                className="block pl-10 py-4 px-0 w-full text-base text-black bg-transparent appearance-none dark:text-green-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label className="peer-focus:font-medium absolute text-base text-green-white italic pl-10  dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5">
                {t("home.subject")}
              </label>
            </div>
            <div className="relative z-0  w-full mb-3 group border-2 border-green-white bg-white pr-2">
              <select
                autoComplete="off"
                type="text"
                name="type"
                id="type"
                className="px-10 focus:outline-none italic focus:ring-lime-500 block py-4 px-0 w-full text-base text-green-white bg-transparent  dark:text-green-white dark:border-black focus:bg-white dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                required
              >
                <option className="bg-white" default>
                  {t("home.type")}
                </option>
                <option className="bg-white">
                  {t("home.type-forms.lecture")}
                </option>
                <option className="bg-white">
                  {t("home.type-forms.workshop")}
                </option>
                <option className="bg-white">
                  {t("home.type-forms.discution-panel")}
                </option>
              </select>
            </div>
            <div className="flex items-stretch relative z-0 w-full h-52 mb-3 group border-2 border-green-white bg-white">
              <textarea
                maxLength="2000"
                autoComplete="off"
                type="text"
                name="message"
                id="message"
                className="block resize-none pl-10 py-4 px-0 w-full text-base text-black bg-transparent appearance-none dark:text-green-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => setCount(e.target.value.length)}
              ></textarea>

              <label className="peer-focus:font-medium absolute text-base text-green-white italic pl-10  dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5">
                {t("home.write-abstract")}
              </label>
              <label className="self-end w-32 pr-2 pb-2 italic text-base text-green-white">
                {count} / 2000
              </label>
            </div>
            <div className="relative w-full  group border-2 border-green-white -mt-3">
              <button
                id="tickets"
                type="submit"
                value="Send"
                className="text-white bg-gradient-to-r from-green-white to-green-dark hover:bg-green-dark focus:ring-4 focus:outline-none focus:bg-lime-900 font-medium text-xl w-full px-5 py-4 text-center"
              >
                {t("home.submit")}
              </button>
            </div>
          </form>
        </section>
        <section
          id="tickets"
          className="grid grid-cols-1 gap-4 justify-items-center pt-12"
        >
          <div
            style={{ fontFamily: "Bebas Neue" }}
            className="text-green-white text-center text-5xl uppercase"
            id="schedule"
          >
            {t("home.tickets")}
          </div>
          <img src={ticket} className="aflex justify-center w-80" alt="logo" />
          <div
            style={{ fontFamily: "Poppins" }}
            className="text-black text-center text-base lg:w-1/2 place-items-center px-2"
          >
            {t("home.tickets-text")}
          </div>
          <div className="pt-8 w-full grid grid-cols-1 gap-4 place-content-center pt-2">
            <div
              style={{ fontFamily: "Poppins" }}
              className=" py-6 lg:px-20 text-white text-center text-lg place-self-center"
            >
              <Link
                className="border-solid bg-teal-600/50 hover:bg-green-white hover:text-white border-2 bg border-white py-6 px-20 text-white text-center text-lg inset-x-2/4 place-self-center "
                to="/tickets"
                id="buyTicket"
              >
                {t("home.buyticket")}
              </Link>
            </div>
          </div>
        </section>
        <section className="grid grid-cols-1 gap-4 justify-items-center pt-12"  style={{maxWidth:'100%', width:'100%'}}>
          <div
            style={{ fontFamily: "Bebas Neue" }}
            className="text-green-white text-center text-5xl"
          >
            {t("home.schedule")}
          </div>
          <Schedule page="teacher" language={i18n.language} />
        </section>
        <section className="grid lg:grid-cols-1 sm:grid-cols-1 gap-4 justify-items-center pt-12">
          <div className="grid grid-cols-1 justify-items-center">
            <div
              style={{ fontFamily: "Bebas Neue" }}
              className="text-green-white text-left text-5xl justify-self-center"
            >
              {t("home.contacts-form")}
            </div>
            <div
              style={{ fontFamily: "Poppins", textAlignLast: "center" }}
              className="text-black lg:w-1/2 px-2 text-justify text-base pt-2 justify-self-center"
            >
              {t("home.contact-form-text")}
            </div>
            <div className="grid lg:grid-cols-2 w-full sm:grid-cols-1 gap-4 auto-cols-auto justify-items-center ">
              <form
                ref={form}
                onSubmit={sendEmail}
                className="lg:w-3/5 sm:w-11/12 w-11/12 pt-12 sm:justify-self-center md:justify-self-center lg:justify-self-end"
              >
                <div className="relative w-full mb-3 group border-2 border-green-white w-90%">
                  <input
                    autoComplete="off"
                    type="text"
                    name="name"
                    id="name"
                    className="block pl-10 py-4 px-0 w-full text-base text-black bg-transparent appearance-none dark:text-green-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label className="peer-focus:font-medium absolute text-base text-green-white italic pl-10 dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5">
                    {t("home.name")}
                  </label>
                </div>
                <div className="relative w-full mb-3 group border-2 border-green-white">
                  <input
                    autoComplete="off"
                    type="email"
                    name="email"
                    id="email"
                    className="block pl-10 py-4 px-0 w-full text-base text-black bg-transparent appearance-none dark:text-green-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label className="peer-focus:font-medium absolute text-base text-green-white italic pl-10  dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5">
                    {t("home.e-mail")}
                  </label>
                </div>

                <div className="relative w-full h-80 group border-2 border-green-white">
                  <textarea
                    maxLength="2000"
                    autoComplete="off"
                    type="text"
                    name="message"
                    id="message"
                    className="block resize-none pl-10 py-8 px-0 w-full text-base text-black bg-transparent appearance-none dark:text-green-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label className="peer-focus:font-medium absolute text-base text-green-white italic pl-10  dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-black peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3">
                    {t("home.your-message")}
                  </label>
                </div>
                <div className="relative w-full mb-3 group bg-lime-600">
                  <button
                    type="submit"
                    value="Send"
                    className="text-white bg-gradient-to-r from-green-white to-green-dark hover:bg-lime-500 focus:ring-4 focus:outline-none focus:bg-lime-900 font-medium text-xl w-full px-5 py-4 text-center"
                  >
                    {t("home.send-contact")}
                  </button>
                </div>
              </form>
              <iframe
                className="pt-12 lg:w-3/5 w-full justify-self-start lg:w-3/5"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.9206553435074!2d11.9709711!3d51.4779707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a6634f340dc411%3A0xce9a18c7bc99a1fa!2sFranckepl.%201%2FHaus%2037%2C%2006110%20Halle%20(Saale)!5e0!3m2!1sde!2sde!4v1686578839866!5m2!1sde!2sde"
                height="494"
                loading="lazy"
                title="google maps"
              ></iframe>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomePageTeachers;
