import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import TicketsForms from "./TicketsForms";
import dropdownButton from "../../svg/dropdownButton.png";
import { useTranslation } from "react-i18next";

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const Ticket = (props) => {
  const [t, i18n] = useTranslation("global");
  const [showTickets, setShowTickets] = useState(false);
  const ClickHandler = () => {
    setShowTickets(!showTickets);
  };

  return (
    <>
      <div className="grid grid-cols-6 bg-white mb-10 xl:text-4xl text-2xl mt-6 h-2/6">
        <div className="grid grid-cols-1 grid-col-3 xl:px-20 py-6 px-6">
          <div className="col-start-1 col-span-3 text-blue-tikets pt-2 xl:w-screen w-64">
            {props.item.event_name} - {props.item.name}
          </div>
          <div
            className="col-start-1 col-span-3 text-slate-400 xl:w-screen w-64 xl:text-2xl text-lg -mt-2"
            style={{ fontFamily: "sf-pro-regular" }}
          >
            {t("tickets.person")},{" "}
            {props.item.from === props.item.to
              ? `${props.item.from}`
              : `${props.item.from} / ${props.item.to}`}
          </div>
        </div>
        <div
          className="xl:col-start-5 col-start-1 col-span-1 flex items-center xl:text-4xl xl:px-0 px-6"
          style={{ fontFamily: "sf-pro-regular" }}
        >
          {t("tickets.price")}{" "}
          <span className="text-blue-tikets">
            {" "}
            {props.item.price.toFixed(2)}€
          </span>
        </div>
        {!showTickets && (
          <button
            className="xl:col-start-6 col-start-1 col-span-6  bg-blue-tikets text-white xl:m-2 xl:ml-14 text-4x "
            onClick={ClickHandler}
          >
            {t("tickets.buy")}
          </button>
        )}
        {showTickets && (
          <div className="col-start-6 col-span-1 flex items-end justify-self-center mb-2">
            <img
              src={dropdownButton}
              className="flex items-center w-10 z-0 col-start-13 pt-12 rotate-180 relative relative absolute xl:-top-0 -top-36"
              alt="logo"
              id="about"
              onClick={ClickHandler}
            />
          </div>
        )}
      </div>
      {showTickets && (
        <TicketsForms
          id={props.item.id}
          price={props.item.price}
          item={props.item}
        />
      )}
    </>
  );
};

export default Ticket;
