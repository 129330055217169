import React, { useState, useEffect } from "react";
import HomePageTeachers from "./HomePageTeachers";
import HomePageUsers from "./HomePageUsers";
import Menu from "./components/Menu";
import background1 from "../svg/background1.png";
import background2 from "../svg/background2.png";
import Footer from "./components/Footer";
import { useTranslation } from "react-i18next";
import PaymentSuccessful from "./PaymentSuccessful";
import PaymentNotSuccessful from "./PaymentNotSuccessful";
import { Route, Routes, useLocation } from "react-router-dom";
import Tickets from "./Tickets";

const MainView = () => {
  const [changeVersionHandler, setchangeVersionHandler] = useState(true);
  const [t, i18n] = useTranslation("global");

  let { pathname } = useLocation();
  let showFooter = true;

  useEffect(() => {
    if (pathname === "/academic") {
      // changeVersionHandler = false;
      setchangeVersionHandler(false);
    }
  }, [pathname]);
  useEffect(() => {
    if (pathname === "/") {
      // changeVersionHandler = false;
      setchangeVersionHandler(true);
    }
  }, [pathname]);

  if (pathname === "/tickets") {
    showFooter = false;
  }

  let background = {
    backgroundImage: `url(${background1})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "top",
  };
  let height = " bg-scroll bg-black";

  if (!changeVersionHandler) {
    background = {
      backgroundImage: `url(${background2})`,
      backgroundRepeat: "no-repeat",
    };
    height = "py-0 ";
  }
  return (
    <div id="home" style={background} className={height}>
      <Menu
        changeVersionHandler={changeVersionHandler}
        ticketsShow={!showFooter}
      />
      <Routes>
        <Route path="/" element={<HomePageUsers />} />
        <Route path="/academic" element={<HomePageTeachers />} />
        <Route path="/tickets" element={<Tickets language={i18n.language}/>} />
        <Route path="/tickets/successful" element={<PaymentSuccessful />} />
        <Route
          path="/tickets/unsuccessful"
          element={<PaymentNotSuccessful />}
        />
      </Routes>
      {showFooter && <Footer show={changeVersionHandler} />}
    </div>
  );
};

export default MainView;
