import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useTranslation } from "react-i18next";
import logo1 from "../../svg/logo1.png";
import logo2 from "../../svg/logo2.png";
import changeVersion from "../../svg/nav-black.png";
import changeVersion_de from "../../svg/nav-black-de.png";
import changeVersion1 from "../../svg/nav-white.png";
import changeVersion1_de from "../../svg/nav-white-de.png";
import changeMobileVersion from "../../svg/mb2.png";
import changeMobileVersion_de from "../../svg/mb2-de.png";
import changeMobileVersion1 from "../../svg/mb1.png";
import changeMobileVersion1_de from "../../svg/mb1-de.png";
import redline from "../../svg/redline.svg";

import HTMLFlipBook from "react-pageflip";
import { Link } from "react-router-dom";
import Language from "./Language";
import dropdownButton from "../../svg/arrrow-green.png";

const Menu = (props) => {
  const [t, i18n] = useTranslation("global");
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Function to handle window resize and update the state
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(()=>{
    if(windowWidth > 1022){
      setShowMenu(false)
    }
    
  })

  const ClickHandler = () => {
    setShowMenu(!showMenu);
  };

  let backgroundHeader =
    "bg-black/90 border-b-2 border-red-800 fixed top-0 left-0 right-0 z-20 lg:h-32";
  if (!props.changeVersionHandler) {
    backgroundHeader =
      "bg-white border-b-2 border-transparent fixed top-0 left-0 right-0 z-20 lg:h-32";
  }

  let colorText =
    "sticky top-0  mx-auto  grid grid-cols-5 w-9/12 text-white text-2xl font-bold underline-offset-0 flex justify-center";
  let colors =
    "text-white hover:text-white text-1xl font-bold underline-offset-0";
  let colorsMobile =
    "text-white hover:text-white text-1xl font-bold underline-offset-0 bg-black flex justify-start pl-8  sm:pl-24 text-3xl w-96 leading-3 flex items-center";
  let colorMobileBg = "bg-black fixed top-16 left-0 right-0 z-20 h-screen";
  if (!props.changeVersionHandler) {
    colorText =
      "sticky top-0 mx-auto  grid grid-cols-5 w-9/12 text-2xl text-black font-bold underline-offset-0 flex justify-center";
    colors =
      "text-1xl text-black hover:text-black font-bold underline-offset-0";
    colorsMobile =
      "text-1xl text-black hover:text-black font-bold underline-offset-0 bg-white flex justify-start pl-8 sm:pl-24 text-3xl w-96 leading-3 flex items-center";
    colorMobileBg = "bg-white fixed top-16 left-0 right-0 z-20 h-screen";
  }
  return (
    <div>
      <div className={backgroundHeader}>
        <nav className={colorText}>
          {props.changeVersionHandler && !props.ticketsShow && (
            <AnchorLink
              href="#home"
              className="flex xl:self-start justify-start w-32 lg:w-full xl:-ml-0 -ml-10"
            >
              <img className="lg:w-60" src={logo1} alt="logo" />
            </AnchorLink>
          )}
          {!props.changeVersionHandler && !props.ticketsShow && (
            <AnchorLink
              href="#home"
              className="flex xl:self-start justify-start w-32 lg:w-full xl:-ml-0 -ml-10"
            >
              <img href="#about" src={logo2} className="lg:w-60" alt="logo" />
            </AnchorLink>
          )}
          {props.changeVersionHandler && props.ticketsShow && (
            <Link
              to={"/"}
              className="flex xl:self-start justify-start w-32 lg:w-full xl:-ml-0 -ml-10"
            >
              <img href="#about" src={logo1} className="lg:w-60" alt="logo" />
            </Link>
          )}
          {!props.changeVersionHandler && props.ticketsShow && (
            <Link
              to={"/academic"}
              className="flex xl:self-start justify-start w-32 lg:w-full xl:-ml-0 -ml-10"
            >
              <img href="#about" src={logo2} className="lg:w-60" alt="logo" />
            </Link>
          )}
          {props.ticketsShow && (
            <button
              onClick={() => navigate(-1)}
              className="col-start-5 flex justify-end text-3xl text-green-ticket  place-content-center -mr-10 lg:mr-0"
            >
              <img
                src={dropdownButton}
                className="w-10 z-0 rotate-90 flex place-self-center"
                alt="logo"
                id="about"
              />
              <div
                className="place-self-center pl-4 font-normal"
                style={{ fontFamily: "sf-pro-medium" }}
              >
                {t("tickets.back")}
              </div>
            </button>
          )}
          {!props.ticketsShow && (
            <div className={`grid xl:grid-cols-7 md:grid-cols-3 sm:grid-cols-3 place-items-center col-start-2 col-span-3 justify-items-end hidden space-x-7 lg:flex self-center break-space ${windowWidth < 1350 ? 'text-base' : ''}`}>
              <AnchorLink
                className={colors}
                style={{ fontFamily: "Poppins" }}
                href="#moreInfo"
              >
                {t("menu.about")}
              </AnchorLink>
              <AnchorLink
                className={colors}
                style={{ fontFamily: "Poppins" }}
                href="#abstracts"
              >
                {t("menu.abstracts")}
              </AnchorLink>
              <Link
                className={colors}
                style={{ fontFamily: "Poppins" }}
                href="#tickets"
                to={"/tickets"}
              >
                {t("menu.get-tickets")}
              </Link>
              <AnchorLink
                className={colors}
                style={{ fontFamily: "Poppins" }}
                href="#schedule"
              >
                {t("menu.schedule")}
              </AnchorLink>
              <AnchorLink
                className={colors}
                style={{ fontFamily: "Poppins" }}
                href="#contacts"
              >
                {t("menu.contacts")}
              </AnchorLink>
              <div style={{ width: "200px", height: "50px", marginTop:"5%" }}>
                <Language />
              </div>
              
            </div>
          )}

          {!props.ticketsShow && props.changeVersionHandler && (
            <Link to="academic" className="col-start-7">
              <img
                className="fixed top-0 right-0 h-32  hidden space-x-8 lg:flex self-center float:right"
                src={ i18n.language === "en" ? changeVersion : changeVersion_de}
                alt="button"
              />
            </Link>
          )}
          {!props.ticketsShow && !props.changeVersionHandler && (
            <Link to="/" className="col-start-7">
              <img
                className="fixed top-0 right-0 h-32  hidden space-x-8 lg:flex self-center"
                src={i18n.language === "en" ? changeVersion1 : changeVersion1_de}
                alt="button"
              />
            </Link>
          )}
          {!props.ticketsShow && (
            <div className="dropdown flex lg:hidden top-0 col-start-6 row-start-1 py-3">
              <button
                onClick={ClickHandler}
                className="space-y-2 border-2 border-green-white py-3 px-2"
              >
                <span className="block w-8 h-0.5 bg-green-white animate-pulse"></span>
                <span className="block w-8 h-0.5 bg-green-white animate-pulse"></span>
                <span className="block w-8 h-0.5 bg-green-white animate-pulse"></span>
              </button>
            </div>
          )}
        </nav>
      </div>
      {showMenu && (
        // <ul className="dropdown-menu absolute hidden text-gray-700 pt-20 inset-y-0 right-0 grid-col-1 w-full">
        <div className={colorMobileBg}  style={{height:"80%"}}>
          <div className="grid grid-col-1 h-1/2">
            <AnchorLink
              className={`${colorsMobile} + pt-2`}
              style={{ fontFamily: "Poppins", marginBottom:"10%", marginTop:"5%"}}
              href="#moreInfo"
              onClick={ClickHandler}
            >
              {t("menu.about")}
            </AnchorLink>
            <AnchorLink
              className={colorsMobile}
              style={{ fontFamily: "Poppins", marginBottom:"10%" }}
              href="#abstracts"
              onClick={ClickHandler}
            >
              {t("menu.abstracts")}
            </AnchorLink>
            <Link
              className={colorsMobile}
              style={{ fontFamily: "Poppins", marginBottom:"10%" }}
              href="#tickets"
              to="/tickets"
              onClick={ClickHandler}
            >
              {t("menu.get-tickets")}
            </Link>
            <AnchorLink
              className={colorsMobile}
              style={{ fontFamily: "Poppins", marginBottom:"10%" }}
              href="#schedule"
              onClick={ClickHandler}
            >
              {t("menu.schedule")}
            </AnchorLink>
            <AnchorLink
              className={colorsMobile}
              style={{ fontFamily: "Poppins"}}
              href="#contacts"
              onClick={ClickHandler}
            >
              {t("menu.contacts")}
            </AnchorLink>
            <div className="h-full">
              <div className="border-t-4 border-red-800 ml-8 sm:ml-24 mr-48 sm:mr-96 mt-10" />
            </div>
            <div className={colorsMobile} style={{marginTop:"5%"}}>
              {t("menu.language")}
              <Language onClick={ClickHandler} />
            </div>
            {props.changeVersionHandler && (
              <Link to="academic" onClick={ClickHandler}  style={{marginTop:"5%",height:"150px"}}>
                <img
                  className="absolute bottom-0 right-0 space-x-8 lg:flex self-center w-1/2"
                  src={i18n.language === "en" ? changeMobileVersion : changeMobileVersion_de}
                  alt="button"
                />
              </Link>
            )}
            {!props.changeVersionHandler && (
              <Link to="/" onClick={ClickHandler} style={{marginTop:"15%",height:"150px"}}>
                <img
                  className="absolute bottom-0 right-0 space-x-8 lg:flex self-center w-1/2"
                  src={i18n.language === "en" ? changeMobileVersion1 : changeMobileVersion1_de}
                  alt="button"
                />
              </Link>
            )}
          </div>
        </div>

        // </ul>
      )}
    </div>
  );
};
// createRoot.render(<Menu />, document.getElementById("content"));

export default Menu;
