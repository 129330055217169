import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/index";
import { Dropdown } from "semantic-ui-react";

const Language = () => {
  const [t, i18n] = useTranslation("global");
  
  const onChangeLang = (e) => {
    const imgElement = e.target.querySelector('img');
    var imgSrc = "";
    if (imgElement) {
      var imgSrc = imgElement.getAttribute('src');
    }
    if (
      e.target.src ===
      "https://img.favpng.com/3/5/21/flag-of-the-united-kingdom-flag-of-great-britain-flag-of-england-png-favpng-q7ZWJzpaYN34ZfrVGRepJsUKN_t.jpg" ||
      imgSrc === "https://img.favpng.com/3/5/21/flag-of-the-united-kingdom-flag-of-great-britain-flag-of-england-png-favpng-q7ZWJzpaYN34ZfrVGRepJsUKN_t.jpg"
    ) {
      i18n.changeLanguage("en");

    } else {
      i18n.changeLanguage("de");
    }
  };


  return (
    <span>
      <Dropdown
        inline
        options={LANGUAGES}
        defaultValue={i18n.language}
        onChange={onChangeLang}
        className={"pl-4"}
        style={{ width: "200px", height: "50px" }}
      />
    </span>
  );
};

export default Language;
