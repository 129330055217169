import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import logo from "../svg/logo.png";
import redline from "../svg/redline.svg";
import button1 from "../svg/button.png";
import ticket from "../svg/ticket.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useTranslation } from "react-i18next";
import Schedule from "./components/Schedule";

const HomePageUsers = () => {
  const [t, i18n] = useTranslation("global");
  const [count, setCount] = useState(0);
  const form = useRef();
  const form2 = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_n3eetpg",
        "template_amw7vd3",
        form.current,
        "kvvx_padstf0o02_b"
      )
      // .sendForm("gmail", "template_752w77a", form.current, "355wjdlIIykPtD9o8")
      .then(
        (result) => {
          console.log(result.text);
          alert("Thank you for contacting us");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const sendProgramEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_n3eetpg",
        "template_i8n2y0c",
        form2.current,
        "kvvx_padstf0o02_b"
      )
      // .sendForm("gmail", "template_h4c464n", form2.current, "355wjdlIIykPtD9o8")
      .then(
        (result) => {
          console.log(result.text);
          alert("Thank you for contacting us");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  let background = {
    backgroundImage: `url(${redline})`,
    // backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "4px",
    fontFamily: "Bebas Neue",
  };
  let button = {
    backgroundImage: `url(${button1})`,
    // backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "4px",
  };
  return (
    <div className="App mx-auto pt-96">
      <div className="grid grid-cols-1 gap-4 justify-items-center pt-40">
        <div
          style={{
            fontFamily: "Bebas Neue",
            textShadow: "rgba(0,0,0,0.5) 1px 0 10px",
            marginTop: "-50px",
          }}
          className="drop-shadow text-white text-center lg:text-9xl text-6xl"
        >
          {t("home.game-studios")}
        </div>
        <div className="w-11/12 px-8 bg/50">
          <div
            className="text-white text-center text-s uppercase"
            style={background}
          >
            Franckeplatz, 1 Haus 37, 06110 Halle
          </div>
        </div>
        <div
          style={{ fontFamily: "Bebas Neue" }}
          className="text-white text-center text-5xl"
        >
          20.10.2023 - 22.10.2023
        </div>
        <div className="w-64 py-1.5 lg:pb-96">
          <AnchorLink style={{ fontFamily: "Bebas Neue" }} href="#moreInfo">
            <div className={`text-center  mx-auto`}>
                <button
              className="text-white w-48 h-20 text-center uppercase text-xl"
              style={{...button, width:"200px", height:"60px"}}
            >
              {t("home.see-more")}
            </button>
            </div>
          
          </AnchorLink>
        </div>
        <img
          src={logo}
          className="animate-spin-slow flex justify-center w-50 z-0"
          alt="logo"
          id="about"
        />
        <div  id="moreInfo" style={{height:"100px"}}></div>
        <section className="grid grid-cols-1 gap-4 justify-items-center pt-12">
          <div
            style={{ fontFamily: "Bebas Neue" }}
            className="text-white text-center text-5xl"
          >
            {t("home.general-information")}
          </div>
          <div
            style={{ fontFamily: "Poppins", textAlignLast: "center" }}
            className="text-white text-justify text-base lg:w-1/2 w-11/12 place-items-center text-align-last:center"
          >
            {t("home.general-info-text-player")}
            <br />
            <br />
            {t("home.location")} <br />
            <a
              className="text-cyan-400 "
              href="https://www.francke-halle.de/en/"
            >
              https://www.francke-halle.de/en
            </a>{" "}
            <br />
            Franckeplatz 1 <br />
            Haus 37
            <br id="abstracts" />
            06110 Halle
          </div>
          <div className="border-b-2 w-48 border-red-800 pt-10"></div>
        </section>

        <section className="grid grid-cols-1 gap-4 justify-items-center pt-12">
          <div
            style={{ fontFamily: "Bebas Neue" }}
            className="text-white text-center text-5xl uppercase"
          >
            {t("home.submit-program-item")}
          </div>
          <div
            style={{ fontFamily: "Poppins", textAlignLast: "center" }}
            className="text-white text-justify text-center text-base lg:w-1/2 w-10/12 place-items-center"
          >
            {t("home.submit-program-item-text-player.text1")}
            <br /> <br />
            {t("home.submit-program-item-text-player.text2")}
            <br /> <br />
            {t("home.submit-program-item-text-player.text3")} <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text-player.text4")} <br />
            </p>
            {t("home.submit-program-item-text-player.text5")} <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text-player.text6")}
              <br /> <br />
            </p>
            <p className="font-bold">
              {t("home.submit-program-item-text-player.text7")} <br />
            </p>
            {t("home.submit-program-item-text-player.text8")} <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text-player.text9")} <br />
            </p>
            {t("home.submit-program-item-text-player.text10")}
            <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text-player.text11")} <br />
            </p>
            {t("home.submit-program-item-text-player.text12")}
            <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text-player.text13")} <br />
            </p>
            {t("home.submit-program-item-text-player.text14")}
            <br /> <br />
            <p className="font-bold">
              {t("home.submit-program-item-text-player.text15")} <br />
            </p>
            {t("home.submit-program-item-text-player.text16")}
          </div>
          <form
            ref={form2}
            onSubmit={sendProgramEmail}
            className="relative z-0 lg:w-2/3 mb-3 group"
            style={{
              width:"90%"
            }}
          >
            <div className="relative z-0 w-full mb-3 group bg-lime-900/50">
              <input
                autoComplete="off"
                type="text"
                name="name"
                id="name"
                className="block  pl-10 py-4 px-0 w-full text-base text-lime-300 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                style={{ fontFamily: "Poppins" }}
                className="peer-focus:font-medium absolute text-base text-white italic pl-10 dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-lime-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5"
              >
                {t("home.name-player")}
              </label>
            </div>
            <div className="relative z-0 w-full mb-3 group bg-lime-900/50">
              <input
                autoComplete="off"
                type="email"
                name="email"
                id="email"
                className="block pl-10 py-4 px-0 w-full text-base text-lime-300 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                style={{ fontFamily: "Poppins" }}
                className="peer-focus:font-medium absolute text-base text-white italic pl-10  dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-lime-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5"
              >
                {t("home.e-mail-player")}
              </label>
            </div>
            <div className="relative z-0 w-full mb-3 group bg-lime-900/50">
              <input
                autoComplete="off"
                type="text"
                name="subject"
                id="subject"
                className="block pl-10 py-4 px-0 w-full text-base text-lime-300 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                style={{ fontFamily: "Poppins" }}
                className="peer-focus:font-medium absolute text-base text-white italic pl-10  dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-lime-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5"
              >
                {t("home.subject-player")}
              </label>
            </div>
            <div className="relative z-0 w-full mb-3 group bg-lime-900/50 pr-2">
              <select
                autoComplete="off"
                type="text"
                name="type"
                id="type"
                className="px-10 outline-pink-900 focus:outline-none italic focus:ring-lime-500 block py-4 px-0 w-full text-base text-white bg-transparent  dark:text-white dark:border-lime-200 focus:bg-lime-700 dark:focus:border-lime-200 focus:outline-none focus:ring-0 focus:border-lime-200 peer"
                placeholder=" "
                required
              >
                <option
                  style={{ fontFamily: "Poppins" }}
                  className="bg-lime-900"
                  default
                >
                  {t("home.type")}
                </option>
                <option
                  style={{ fontFamily: "Poppins" }}
                  className="bg-lime-900"
                >
                  {t("home.type-forms.lecture")}
                </option>
                <option
                  style={{ fontFamily: "Poppins" }}
                  className="bg-lime-900"
                >
                  {t("home.type-forms.workshop")}
                </option>
                <option
                  style={{ fontFamily: "Poppins" }}
                  className="bg-lime-900"
                >
                  {t("home.type-forms.discution-panel")}
                </option>
              </select>
            </div>
            <div className="flex items-stretch  relative z-0 w-full h-52 group bg-lime-900/50">
              <textarea
                maxLength="2000"
                autoComplete="off"
                type="text"
                name="message"
                id="message"
                className="block resize-none pl-10 py-4 px-0 w-full text-base text-lime-300 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e) => setCount(e.target.value.length)}
              />
              <label
                style={{ fontFamily: "Poppins" }}
                className="peer-focus:font-medium absolute text-base text-white italic pl-10  dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-lime-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5"
              >
                {t("home.write-abstract-player")}
              </label>
              <div
                style={{ fontFamily: "Poppins" }}
                className="self-end w-32 pr-2 pb-2 italic text-base text-white"
              >
                {count} / 2000
              </div>
            </div>
            <div className="relative z-0 w-full group bg-lime-900/50">
              <button
                id="tickets"
                className="text-white bg-lime-600 text-xl hover:bg-lime-500 focus:ring-4 focus:outline-none focus:bg-lime-900 font-medium text-base w-full px-5 py-4 text-center"
              >
                {t("home.submit")}
              </button>
            </div>
          </form>
          <div className="border-b-2 w-48 border-red-800 pt-10"></div>
        </section>
        <section className="grid grid-cols-1 gap-4 justify-items-center pt-12">
          <div
            style={{ fontFamily: "Bebas Neue" }}
            className="text-white text-center text-5xl uppercase"
          >
            {t("home.tickets")}
          </div>
          <img src={ticket} className="aflex justify-center w-80" alt="logo" />
          <div
            style={{ fontFamily: "Poppins", textAlignLast: "center" }}
            className="text-white text-justify text-base lg:w-1/2 w-11/12 place-items-center"
            id="schedule"
          >
            {t("home.tickets-text-player")}

          </div>


          <Link
            style={{ fontFamily: "Bebas Neue" }}
            to= {"/tickets"} 
            id="buyTicket"
            className="pt-10"
          >
            <button
              className="text-white w-48 h-20 text-center uppercase text-xl"
              style={{...button, width:"200px", height:"60px"}}
            >
              {t("home.buyticket")}
            </button>
          </Link>
          <div className="border-b-2 w-48 border-red-800 pt-10"></div>
        </section>

        <section className="grid grid-cols-1 gap-4 justify-items-center pt-12" style={{maxWidth:'100%', width:'100%'}}>
          <div
            style={{ fontFamily: "Bebas Neue" }}
            className="text-white text-center text-5xl"
          >
            {t("menu.schedule")}
          </div>
          <Schedule page="teacher" language={i18n.language} />
        </section>
        <section className="grid lg:grid-cols-1 sm:grid-cols-1 gap-4 justify-items-center pt-12" id="contacts">
          <div className="grid grid-cols-1 justify-items-center">
            <div className="grid lg:grid-cols-2 w-full sm:grid-cols-1 gap-4 auto-cols-auto justify-items-center ">
              <div className="lg:w-3/5 pt-12 lg:justify-self-end">
                <div
                  style={{ fontFamily: "Bebas Neue" }}
                  className="text-white px-2 text-center lg:text-left text-5xl lg:justify-self-start justify-self-center lg:w-3/5"
                >
                  {t("home.contacts-form")}
                </div>
                <div
                  style={{ fontFamily: "Poppins" }}
                  className="px-2 text-white text-center lg:text-left text-base pt-2 lg:justify-self-start justify-self-center "
                >
                  {t("home.contact-form-text-player")}
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-2 w-full sm:grid-cols-1 gap-4 auto-cols-auto justify-items-center ">
              <form
                ref={form}
                onSubmit={sendEmail}
                className="lg:w-3/5 sm:w-11/12 w-11/12 pt-12 lg:justify-self-end"
              >
                <div className="relative z-0 w-full mb-3 group bg-lime-900/50 w-90%">
                  <input
                    autoComplete="off"
                    type="text"
                    name="name"
                    id="name"
                    className="block pl-10 py-4 px-0 w-full text-base text-lime-300 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label
                    style={{ fontFamily: "Poppins" }}
                    className="peer-focus:font-medium absolute text-base text-white italic pl-10 dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-lime-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5"
                  >
                    {t("home.name-player")}
                  </label>
                </div>
                <div className="relative z-0 w-full mb-3 group bg-lime-900/50">
                  <input
                    autoComplete="off"
                    type="email"
                    name="email"
                    id="email"
                    className="block pl-10 py-4 px-0 w-full text-base text-lime-300 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label
                    style={{ fontFamily: "Poppins" }}
                    className="peer-focus:font-medium absolute text-base text-white italic pl-10  dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-lime-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5"
                  >
                    {t("home.e-mail-player")}
                  </label>
                </div>

                <div className="relative z-0 w-full h-96 mb-3 group bg-lime-900/50">
                  <textarea
                    maxLength="2000"
                    autoComplete="off"
                    type="text"
                    name="message"
                    id="message"
                    className="block resize-none pl-10 py-4 px-0 w-full h-52 mt-2 text-base text-lime-300 bg-transparent appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label className="peer-focus:font-medium absolute text-base text-white italic pl-10 dark:text-gray-400 duration-300 transform -translate-y-5 scale-75 top-5 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-lime-200 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-5">
                    {t("home.your-message-player")}
                  </label>
                </div>
                <div className="w-48 z-0 w-full mb-3">
                  <div className="bg-black py-1.5">
                    <div className={`text-center ${window.innerWidth < 640 ? 'mx-auto' : ''}`}>
                      <button
                        className="text-white w-48 h-20 text-center uppercase text-xl outline-pink-900"
                        style={{ ...button, width: "200px", height: "60px" }}
                      >
                        {t("home.send")}
                      </button>
                      <div className="border-b-2 w-64 border-red-800 pt-4 mx-auto"></div>
                    </div>  
                  </div>
                </div>
              </form>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.9206553435074!2d11.9709711!3d51.4779707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a6634f340dc411%3A0xce9a18c7bc99a1fa!2sFranckepl.%201%2FHaus%2037%2C%2006110%20Halle%20(Saale)!5e0!3m2!1sde!2sde!4v1686578839866!5m2!1sde!2sde"
                // width="410"
                height="494"
                loading="lazy"
                title="google maps"
                className="pt-12 lg:w-3/5 w-full justify-self-start lg:w-3/5"
              ></iframe>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomePageUsers;
