import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import paymeBackground from "../svg/paymeBackground.png";
import dropdownButton from "../svg/dropdownButton.png";
import Ticket from "./components/Ticket";
import {Icon} from "semantic-ui-react";
import {InformationCircleIcon} from "@heroicons/react/20/solid";

const Tickets = (props) => {
  const [t, i18n] = useTranslation("global");
  const [showTickets, setShowTickets] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [typeTicketsState, setTypeTickets] = useState();
  const [data, setData] = useState([]);
  let background = {
    backgroundImage: `url(${paymeBackground})`,
    height: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "top",
    width: "100%",
    marginBot: "-100px",
  };

  const ClickHandler = () => {
    setShowTickets(!showTickets);
  };

  useEffect(()=>{
    setSelectedLanguage(props.status);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    };
  
    const fetchSchedule = async () => {
      try {
        const response = await fetch(
          "https://backend.pnpcongress.eu/api/tickets_types/"+props.language
        );
        const json_data = await response.json();
        
        if (json_data["message"] === "Success") {
          const formattedData = json_data["data"].map((item) => ({
            ...item,
            from: formatDate(item.from),
            to: formatDate(item.to),
            
          }))
          setTypeTickets(formattedData)
        }else {
          //
        }
      } catch (error) {
        // Handle API call errors if needed
        console.error("Error fetching events:", error);
      }
    }
    fetchSchedule();
  }, [props.language]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <div style={background} className=" ">
      <div className="App mx-auto flex justify-center ">
        <div
          className="pt-20 xl:pt-64 xl:w-9/12 w-11/12 h-screen"
          style={{ fontFamily: "sf-pro-medium" }}
        >
          {/* <div className="grid grid-cols-2 w-32 items-center">
          <div className="rounded-full bg-black w-10 h-10 flex justify-center items-center text-white">
            U
          </div>
          <div className="text-blue-tikets w-64 uppercase">
            <div className="flex justify-self-start w-20 text-3xl ">
              Hello, <br />
            </div>
            user name
          </div>
        </div> */}


          <div className="col-start-1 col-span-1 text-blue-tikets xl:pt-10 pt-12 w-full text-xl text-center">
        <span style={{ display: "flex", alignItems: "center" }}>
          <InformationCircleIcon width={"2%"} style={{ marginRight: "8px" }} />
          <span style={{ fontWeight: "bold" }}>
            {t("home.tickets-student-discount")}
          </span>
        </span>
          </div>


          <div className="grid grid-cols-10 pb-10 xl:pb-20 xl:h-24 h-32">
            <div className="col-start-1 col-span-1 text-blue-tikets xl:pt-10 pt-12 w-full text-3xl">
              {t("tickets.buy_tickets")}
            </div>
            <div className="xl:col-start-2 col-start-4 col-end-13 mx-8 border-b-2  grow xl:mb-4 mb-10"></div>
            {/*<img
              src={dropdownButton}
              className="flex justify-center w-10 z-0 col-start-13 xl:pt-8 pt-14"
              alt="logo"
              id="about"
              onClick={ClickHandler}
            />*/}
          </div>
          {showTickets && typeTicketsState &&
            typeTicketsState.map((item) => {
              return <Ticket key={item.id} item={item} language={props.language}></Ticket>;
            })}
        </div>
      </div>
      <div className="pt-6">
        <div className="pt-10 z-40 bg-blue-tikets w-screen"></div>
      </div>
    </div>
  );
};

export default Tickets;
