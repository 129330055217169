import React, { useEffect, useState } from 'react';

const EventDot = ({event, index, setOpenEvent, setSelectedRoom, openEvent}) => {
    const handleButtonClick = () => {
        setSelectedRoom(event.room);
        setOpenEvent(event);
      };

      const referencePoints = [
        { maxWidth: 1023, translateX: -30, translateY: 0 },
        { maxWidth: 2561, translateX: 0, translateY: -19 },
      ];
      
      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
      const [translateX, setX] = useState(0);
      const [translateY, setY] = useState(0);
  
      // Function to handle window resize and update the state
      const handleResize = () => {
        const newWindowWidth = window.innerWidth;
        setWindowWidth(newWindowWidth);
      };
  
      
    useEffect(() => {
        const matchingReference = referencePoints.find(
            (point) => windowWidth <= point.maxWidth
          );
          if (matchingReference) {
            // Set translateX and translateY based on the matching reference
            setX(matchingReference.translateX);
            setY(matchingReference.translateY);
          }
    },[windowWidth])

    useEffect(() => {
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const divTranslateYStyle = {
        transform: `translateY(${translateY}px)`,
        transform: `translateY(${translateY}px)`,
        fontFamily: "BebasNeueRegular"
      };

    const divTranslateXStyle = {
        transform: `translateX(${translateX}px)`,
        fontFamily: "BebasNeueRegular"
    };  

     
    return(
        <>
        {windowWidth < 1024 && (
            
            <div className={`min-h-[200px] break-words`} id={`${event.name}`} style={divTranslateXStyle}>
            <button
                key={index+ 1}       
                className={`text-left focus:text-green-ticket active:text-green-ticket ${event === openEvent ? 'text-green-ticket' : ''}`}
                style={{fontFamily: "BebasNeueRegular"}}
                onClick={handleButtonClick}
            >     
        
            <div className="grid grid-flow-col auto-cols-auto">
                <div >
                        <div className="w-8 h-8 bg-white rounded-full ">
                        {" "}
                        </div>
                        <div className="w-6 h-6 bg-black rounded-full lg:-mt-7 lg:-ml-0 ml-1 -mt-7 lg:ml-1">
                        {" "}
                        </div>
                        <div
                            className="w-4 h-4 bg-green-ticket shadow-inner rounded-full rotate-180 lg:-mt-5 lg:ml-2 ml-2 -mt-5"
                            style={{
                            boxShadow: "inset 0 8px 6px -6px black",
                            }}
                        >
                        {" "}
                        </div>  
                </div>
                <div className=" ml-3 lg:ml-0 lg:mt-8 mt-0 lg:translate-y-[-10%]">
                    {(
                        <div >
                            {event.timeStart && (
                        <div className="pr-5 lg:text-2xl sm:text-xl text-lg pt-0  ">
                            {event.timeStart} - {event.timeEnd}
                        </div>
                    )}
                    {event.name && (
                        <div className="pr-5 lg:text-2xl sm:text-xl text-lg  leading-5 text-justify mr-3" style={{whiteSpace: 'nowrap'}}>
                            {event.host_name}: {event.name}
                        </div>
                    )}
                    </div>
                    )}
                    </div>
                </div>
                </button>
            </div>     
          )}
        {windowWidth >= 1024 && (
              <div className={`min-h-[200px] break-words`}  id={`${event.name}`} style={divTranslateYStyle}>
              <button
                  key={index+ 1}
                  className={`text-left focus:text-green-ticket active:text-green-ticket ${event === openEvent ? 'text-green-ticket' : ''}`}
                  style={{fontFamily: "BebasNeueRegular"}}
                  onClick={handleButtonClick}
              >     
          
              <div className="grid md:auto-rows-auto md:auto-cols-auto lg:auto-rows-auto auto-cols-auto">
                  <div >
                          <div className="w-8 h-8 bg-white rounded-full ">
                          {" "}
                          </div>
                          <div className="w-6 h-6 bg-black rounded-full lg:-mt-7 lg:-ml-0 ml-1 -mt-7 lg:ml-1">
                          {" "}
                          </div>
                          <div
                              className="w-4 h-4 bg-green-ticket shadow-inner rounded-full rotate-180 lg:-mt-5 lg:ml-2 ml-2 -mt-5"
                              style={{
                              boxShadow: "inset 0 8px 6px -6px black",
                              }}
                          >
                          {" "}
                          </div>  
                  </div>
                  <div className=" ml-3 lg:ml-0 lg:mt-8 mt-0 lg:translate-y-[-10%]">
                      {(
                          <div >
                              {event.timeStart && (
                          <div className="pr-5 lg:text-2xl sm:text-xl text-lg pt-0  ">
                              {event.timeStart} - {event.timeEnd}
                          </div>
                      )}
                      {event.name && (
                          <div className="pr-5 lg:text-2xl sm:text-xl text-lg leading-5 overflow-hidden mr-3" style={{ wordBreak:'break-word'}}>
                              {event.host_name}: {event.name}
                          </div>
                      )}
                      </div>
                      )}
                      </div>
                  </div>
                  </button>
              </div>   
          )}
    </>
    )}
export default EventDot;