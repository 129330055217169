import React, { useEffect, useState } from 'react';

const EventDiamond = ({event, index, setOpenEvent, setSelectedRoom}) => {

  // Function to handle window resize and update the state
  const referencePoints = [
    { maxWidth: 1023, translateX: -30, translateY: 0 },
    { maxWidth: 2561, translateX: 0, translateY: -19 },
  ];
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [translateX, setX] = useState(0);
  const [translateY, setY] = useState(0);

  // Function to handle window resize and update the state
  const handleResize = () => {
    const newWindowWidth = window.innerWidth;
    setWindowWidth(newWindowWidth);
  };

    
  useEffect(() => {
      const matchingReference = referencePoints.find(
          (point) => windowWidth <= point.maxWidth
        );
        if (matchingReference) {
          // Set translateX and translateY based on the matching reference
          setX(matchingReference.translateX);
          setY(matchingReference.translateY);
        }
  },[windowWidth])

  const divTranslateYStyle = {
    transform: `translateY(${translateY}px)`,
    transform: `translateY(${translateY}px)`,
    fontFamily: "BebasNeueRegular"
  };

  const divTranslateXStyle = {
    transform: `translateX(${translateX}px)`,
    fontFamily: "BebasNeueRegular"
  };  


  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
return(   
  <>
  {windowWidth < 1024 && (
    <div className='min-h-[200px] break-words' style={divTranslateXStyle}>
    <button
        key={index+ 1}
        className="text-left"
        style={{ fontFamily: "BebasNeueRegular"}}
        disabled
    >

    {(
    
      <div className='grid grid-flow-col auto-cols-auto'>
        <div>
          <div className="w-8 h-8 bg-white rotate-45 lg:-mt-5 lg:-ml-0">
            {" "}
          </div>
          <div className="w-6 h-6 bg-black rotate-45 lg:-mt-7 ml-1 lg:ml-1 -mt-7">
            {" "}
          </div>
          <div
              className="w-4 h-4 bg-green-ticket shadow-inner ml-2 lg:-mt-5 lg:ml-2 -mt-5"
              style={{
                boxShadow: "inset 0 8px 6px -6px black",
                transform: "rotate(225deg)",
              }}
          >
            {" "}
          </div>
        </div>
        <div className=" ml-3 lg:ml-0 lg:mt-8 mt-0 lg:translate-y-[-10%]">
          {event.timeStart && (
          <div className="pr-5 lg:text-2xl text-lg leading-5 lg:mt-4 mt-0">
          {event.timeStart} - {event.timeEnd}
          </div>
          )}
          {event.name && (
          <div className="pr-5 lg:text-2xl text-lg leading-5 overflow-hidden break-all mr-3">
        {event.name}
          </div>
          )}
        </div>
      </div>
      )}
    
  </button>
  </div>
  )}
  {windowWidth >= 1024 && (
    <div className='min-h-[200px] break-words' style={divTranslateYStyle}>
    <button
        key={index+ 1}
        className="text-left"
        style={{ fontFamily: "BebasNeueRegular"}}
        disabled
    >

    {(
    
      <div className='grid md:auto-rows-auto md:auto-cols-auto lg:auto-rows-auto auto-cols-auto'>
        <div>
          <div className="w-8 h-8 bg-white rotate-45 lg:-mt-5 lg:-ml-0">
            {" "}
          </div>
          <div className="w-6 h-6 bg-black rotate-45 lg:-mt-7 ml-1 lg:ml-1 -mt-7">
            {" "}
          </div>
          <div
              className="w-4 h-4 bg-green-ticket shadow-inner ml-2 lg:-mt-5 lg:ml-2 -mt-5"
              style={{
                boxShadow: "inset 0 8px 6px -6px black",
                transform: "rotate(225deg)",
              }}
          >
            {" "}
          </div>
        </div>
        <div className=" ml-3 lg:ml-0 lg:mt-8 mt-0 lg:translate-y-[-10%]">
          {event.timeStart && (
          <div className="pr-5 lg:text-2xl text-lg leading-5 lg:mt-4 mt-0">
          {event.timeStart} - {event.timeEnd}
          </div>
          )}
          {event.name && (
          <div className="pr-5 lg:text-2xl text-lg leading-5 overflow-hidden break-all mr-3">
        {event.name}
          </div>
          )}
        </div>
      </div>
      )}
    
  </button>
  </div>
  )}
  </> 
  
  ) 
}


export default EventDiamond;