import "./App.css";
import MainView from "./views/MainView";
import { Suspense } from "react";

function App() {
  return (
    <Suspense className="App container mx-auto" fallback="loading">
      <MainView />
    </Suspense>
  );
}

export default App;
